<script context="module">
  import AdminLayout from 'lyo/AdminLayout.svelte';
  //import NestedLayout from 'lyo/NestedLayout.svelte';

  export const layout = [AdminLayout];
</script>

<script>
    import { inertia, Link, page } from '@inertiajs/svelte';
    import { onMount } from 'svelte';

    
     export let pagename = 'Match List';
	   export let appName = $page.props.appName;
     export let message = "Standard view loaded.";
     export let hash = $page.props.hash;
     export let playerList, pairingList, matchList, matchTable;
     let showMessage = false;

     

     onMount(() => {
        if($page.props.message) {
          showMessage = true;
        }

        console.log("matchTable");
        console.log($page.props.matchTable[0]);

        console.log("matchList");
        console.log($page.props.matchList[0]);

      });
</script>

<svelte:head>
    <title>{pagename} > { appName }</title>

    

</svelte:head>



  <main class="m-4">
  	<h2 class="text-2xl text-primary my-4 ml-8">Match list for "{hash}" 
    {#if $page.props.message}
      <span class="text-white badge badge-warning">{$page.props.message}</span>
    {:else}

    {/if}
    </h2>

    {#if showMessage }
    <div role="alert" class="alert hidden">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        class="stroke-info h-6 w-6 shrink-0">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
      </svg>
      <span>{message}</span>
    </div>
    {/if}




    <h3 class="mt-6 mb-2 py-2 px-4 bg-base-200"><a id="#players">Match assignments and results</a></h3>

    <a class="link text-sm link-primary" href="{route('debug.session.inspect', hash)}">Open session inspect view</a>
    <table class="table table-xs table-hover text-left border border-base-200">
        <thead>
          <tr class="text-center bg-base-200">
            <th>#</th>
            {#each matchTable[0] as item,i}
            <th>{#if i == 0}Name{/if}
                {#if i == 1} {/if}
                {#if i > 0 && i < matchTable[0].length - 2 } {i-1} {/if}</th>  
            {/each}

          </tr>
        </thead>
        <tbody>
          {#each matchTable as row,i}
          <tr class:text-neutral={false} class="hover">
            <th>{i}</th>
            {#each row as item}
            <td
              class={`border-r border-base-200 text-center
                ${item.startsWith('C') ? 'bg-green-100 hover:bg-green-300 dark:bg-green-800 dark:text-neutral' : ''}
                ${item.startsWith('C') ? 'bg-blue-100 hover:bg-blue-300 dark:bg-blue-800 dark:text-neutral' : ''}
                ${item==="0" ? 'text-neutral' : ''}

              `}
            >
            {#if (item.startsWith('A') || item.startsWith('B')) }
                <div class={`badge badge-outline ${item.startsWith('A') ? 'badge-primary' : ''} ${item.startsWith('B') ? 'badge-secondary' : ''}`}>{item[0]}</div>
            {:else}
              <span class="{item == "21" ? 'font-bold' : '' }">{item}</span>
            {/if}
            
            </td>
            {/each}
          </tr>
          {/each}
        </tbody>
      </table>


      <h3 class="mt-6 mb-2 py-2 px-4 bg-base-200"><a id="#matches">Scheduled matches</a></h3>
    <table class="table table-xs table-hover text-center max-w-2xl">
      <thead>
        <tr>
          <th>#</th>
          <th>Round</th>
          <th>Team A</th>
          <th>Score</th>
          <th>Team B</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
          {#each matchList as row, i}
            <tr class="hover {(row.round % 2 == 0) ? 'bg-primary/10':''}">
              <td>{row.matchNumber}</td>
              <td class="">{row.round}</td>
              <td class="{row.winner == 1 ? 'font-bold' : ''}">
                <span class="text-nowrap">{row['teamA'][0]}</span> /
                <span class="text-nowrap">{row['teamA'][1]}</span>
              </td>
              <td class="">
                {#if row.status == "finished" }
                {row.score[0]}&nbsp;:&nbsp;{row.score[1]}
                {:else}
                  <span class="text-neutral">--:--</span>
                {/if}
              </td>
              <td  class="{row.winner == 2 ? 'font-bold' : ''}">
                <span class="text-nowrap">{row['teamB'][0]}</span> /
                <span class="text-nowrap">{row['teamB'][1]}</span>
              </td>
              <td class="{row.status == 'finished' ? 'text-neutral' : ''}">{row.status}</td>
            </tr>
            {:else}
              <tr>
                <td colspan="5"><h2 class="text-2xl text-neutral text-center"> List is empty</h2></td>
              </tr>
          {/each}
          </tbody>
    </table>



  </main>