<script>
  
  import { inertia } from '@inertiajs/svelte';

</script>


<main class="bg-blueGray-300 text-coolGray-700 border border-black p-4 m-4">

This is nested layout

<slot />

</main>

<!-- Page content here -->