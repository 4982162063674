<script>

  // This layout uses HeroIcons for menu icons
  
  import { inertia, Link, page, router } from '@inertiajs/svelte';
  import { slide } from 'svelte/transition';

  import Navlink from 'cmpt/Navlink.svelte';
  import Logo from 'cmpt/snippets/logo.svelte';

  export let playerCount = 8;
  export let firstLetterNumber = 0;
  export let maxLetterNumber = 26; //
  export let firstLetter = "A";
  export let generateSessionUrl = route('debug.session.generate');

  export let hash = $page.props.hash;
  export let routeList = $page.props.routeList;

  $: {
    firstLetter = intToChar(firstLetterNumber);
    generateSessionUrl = route('debug.session.generate', { size:playerCount, offset: firstLetterNumber});
    // console.log(generateSessionUrl);
  }
  $: maxLetterNumber = 26 - playerCount;
  $: if(firstLetterNumber > maxLetterNumber) {
      firstLetterNumber = maxLetterNumber;
    }


  function goGenerate() {
    let url = route('debug.session.generate', { size:playerCount, offset: firstLetterNumber});

    console.log(`Clicking: ${url}`)
    router.get(url);
  }

  function intToChar(num) {
    if (num < 0 || num > 25) {
      return "_"; // Handle invalid input
    }
    return String.fromCharCode(65 + num); // 65 is the ASCII code for 'A'
  }

  function nothing() {
    return false;
  }

</script>


<div class="adminLayout">

    <div class="flex h-screen">

<!-- Desktop sidebar -->
      <aside class="z-20 w-64 overflow-y-auto bg-base-100 md:block flex-shrink-0 border-r">

      <div class="py-4">
          <a class="block ml-6 text-lg font-bold"
            href="{route('debug.session.inspect')}">
            <img href="/img/logo_playminton_tr2.png" alt="Playminton inspector" class="w-24 h-auto" />
          </a>

        <ul class="menu mt-8"> <!-- start of main nav menu -->


<!-- Left navigation -->
  
          <li>
            <details open>
              <summary>Lists of all</summary>
              <ul>

                <Navlink href="{route('debug.session.listall', hash)}" class="">
                  <svelte:fragment slot="svgIconPath">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 0 1 0 3.75H5.625a1.875 1.875 0 0 1 0-3.75Z" />
                  </svelte:fragment>
                  <span slot="navLabel">Sessions</span>
                </Navlink>

                <Navlink href="{route('debug.quickevents.listall', hash)}" class="">
                  <svelte:fragment slot="svgIconPath">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5.09 10.121A5.251 5.251 0 0 1 1 5V3.75C1 2.784 1.784 2 2.75 2h2.364c.236-.586.81-1 1.48-1h10.812c.67 0 1.244.414 1.48 1h2.489c.966 0 1.75.784 1.75 1.75V5a5.252 5.252 0 0 1-4.219 5.149 7.01 7.01 0 0 1-4.644 5.478l.231 3.003a.5.5 0 0 0 .034.031c.079.065.303.203.836.282.838.124 1.637.81 1.637 1.807v.75h2.25a.75.75 0 0 1 0 1.5H4.75a.75.75 0 0 1 0-1.5H7v-.75c0-.996.8-1.683 1.637-1.807.533-.08.757-.217.836-.282a.5.5 0 0 0 .034-.031l.231-3.003A7.012 7.012 0 0 1 5.09 10.12ZM6.5 2.594V9a5.5 5.5 0 1 0 11 0V2.594a.094.094 0 0 0-.094-.094H6.594a.094.094 0 0 0-.094.094Zm4.717 13.363-.215 2.793-.001.021-.003.043a1.212 1.212 0 0 1-.022.147c-.05.237-.194.567-.553.86-.348.286-.853.5-1.566.605a.478.478 0 0 0-.274.136.264.264 0 0 0-.083.188v.75h7v-.75a.264.264 0 0 0-.083-.188.478.478 0 0 0-.274-.136c-.713-.105-1.218-.32-1.567-.604-.358-.294-.502-.624-.552-.86a1.22 1.22 0 0 1-.025-.19l-.001-.022-.215-2.793a7.069 7.069 0 0 1-1.566 0ZM19 8.578A3.751 3.751 0 0 0 21.625 5V3.75a.25.25 0 0 0-.25-.25H19ZM5 3.5H2.75a.25.25 0 0 0-.25.25V5A3.752 3.752 0 0 0 5 8.537Z" />
                  </svelte:fragment>
                  <span slot="navLabel">Quickevents</span>
                </Navlink>
              </ul>
            </details>
          </li>

          <li>
            <details open>
              <summary>Session views</summary>

              <ul>
                <Navlink href="{route('debug.session.inspect', hash)}">
                  <svelte:fragment slot="svgIconPath">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25" />
                  </svelte:fragment>
                  <span slot="navLabel">Session overview</span>
                </Navlink>

            		<Navlink href="{route('debug.session.matchlist', hash)}">
            			<svelte:fragment slot="svgIconPath">
            				<path stroke-linecap="round" stroke-linejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z" />
            			</svelte:fragment>
            			<span slot="navLabel">Match List</span>
            		</Navlink>

                <Navlink href="{route('debug.players.list.editform', hash)}">
                  <svelte:fragment slot="svgIconPath">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
                  </svelte:fragment>
                  <span slot="navLabel">Edit player List</span>
                </Navlink>
              </ul>
            </details>
          </li>

          <li> 
            <details open>
              <summary>Session commands</summary>
              <ul>
                
            		<Navlink href="{route('debug.session.schedule', hash)}">
            			
                  <svelte:fragment slot="svgIconPath">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                  </svelte:fragment>
            			<span slot="navLabel">Generate one round</span>
            		</Navlink>

                <Navlink href="{route('debug.session.makematch', hash)}">
                  
                  <svelte:fragment slot="svgIconPath">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                  </svelte:fragment>
                  <span slot="navLabel">Generate single match</span>
                </Navlink>

              </ul>
            </details>

          </li>




          <li>
            <details>
            <summary>Generate new...</summary>
            <ul>
              <li class="block">
                <label class="form-control w-full max-w-xs">
                  <div class="label">
                    <span class="label-text">Players</span>
                  </div>
                  <input type="range" min="6" max="25" bind:value={playerCount} class="range range-primary range-xs" />
                  <div class="label">
                    <span class="label-text-alt">{playerCount}</span>
                  </div>
                </label>
              </li>


              <li class="block">
                <label class="form-control w-full max-w-xs">
                  <div class="label">
                    <span class="label-text">Start names on</span>
                  </div>
                  <input type="range" min="0" max="{maxLetterNumber}" bind:value={firstLetterNumber} class="range range-primary range-xs" />
                  <div class="label">
                    <span class="label-text-alt">{firstLetter}</span>
                  </div>
                </label>
              </li>
          		<li class="block pl-4">
          			<button on:click={ goGenerate } class="btn btn-primary btn-outline">
          				Generate new session
          			</button>
          		</li>
            </ul>
            </details>
          </li>





          <li>
              <details>
                <summary>App view list</summary>
                <ul>
                  {#each routeList as item, i}
                  {#if item.namespace === null}
                  <li class="my-2"><span title="{i}: {item.name}" class={`text-sm p-0 ${item.name.startsWith('debug.') ? 'text-secondary' : 'text-primary'}`}>/{item.uri}</span>
                  </li>
                  {/if}
                  {/each}
                  
                </ul>
              </details>
            </li>

            <li>
              <details>
                <summary>Fortify views list</summary>
                <ul>
                  {#each routeList as item, i}
                  {#if item.namespace && item.namespace.includes("\\Fortify\\")}
                  <li class="my-2"><Link title="{i}: {item.name}" class={`link text-sm p-0 ${item.name.startsWith('debug.') ? 'link-secondary' : 'link-primary'}`} href="{route(item.name, hash)}" target="_blank" rel="noreferrer">/{item.uri.replace(/\{hash\??\}/g, hash)}</Link>
                  </li>
                  {/if}
                  {/each}
                  
                </ul>
              </details>
            </li>

      		</ul> <!-- end of menu -->


    		<!-- <div class="px-6 my-6 hidden">
    			<a href="#top"
    			class="btn btn-outline btn-sm">
    			<span class="mr-2" aria-hidden="true">+</span>
    			Some button
    			</a>
    		</div> -->


  	   </div>
      </aside>


<!-- Backdrop, shows only when mobile menu is open -->
<!-- TODO: Implement svelte native show/hide animation  -->
      <div
        class="hidden fixed inset-0 z-10 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
      ></div>

<!-- Mobile sidebar starts -->
      <aside
        class="fixed inset-y-0 z-20 flex-shrink-0 w-64 mt-16 overflow-y-auto bg-white dark:bg-gray-800 md:hidden"
        onClick="closeSideMenu()"
        onKeydown="closeSideMenu()"
      >
        <div class="py-4 text-gray-500 dark:text-gray-400">
          <a
            class="ml-6 text-lg font-bold text-gray-800 dark:text-gray-200"
            href="#top"
          >
            <Logo color={'#444444'} height={200} width={250} message={'b Logo'}/>
          </a>

<!-- TODO implement menu content copying from main menu -->
          
        <p>Mobile menu is not implemented yet. Please use large sized screen </p>

        </div>
      </aside>









<!-- HEADER STARTS HERE ##################################################################################################### -->
      <div class="flex flex-col flex-1 w-full">
        <header class="z-10 py-4 bg-base-100 shadow-md">
          <div class="container flex items-center justify-between h-full px-6 mx-auto">

            <div class="navbar bg-base-100 hidden">
              <div class="flex-1">
                <Logo color={'#444444'} height={50} width={300} message={'Playminton test'} />
              </div>
              <div class="flex-none gap-2">
                <div class="form-control">
                  <input type="text" placeholder="Global search..." class="input input-bordered" />
                </div>
                <div class="dropdown dropdown-end">
                  <label class="btn btn-circle avatar placeholder">
                    <div class="w-10 rounded-full" title="user.name">
                      <span>AA</span>
                    </div>
                  </label>
                  <ul class="mt-3 p-2 shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-52">
                    <li>
                      <a class="justify-between">
                        Profile
                        <span class="badge">New</span>
                      </a>
                    </li>
                    <li><a href="#">Settings</a></li>
                    <li>
                    	<Link href={nothing} method="post" as="button" class="link link-hover link-primary">
          							Logout
          						</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </header>

        <main class="h-full overflow-y-auto">
        	<div class="container px-6 mx-auto grid">
          	<slot>
          		<h2 class="my-6 text-2xl font-semibold">No content in this view yet... :/ </h2>

          	</slot>
	
			   </div>	
		  </main>
	   </div> <!-- Header wrapper end -->


  </div>
</div>