<script context="module">
  import SiteLayout from 'lyo/AppLayout.svelte';
  export const layout = [SiteLayout];
</script>

<script>
  import { inertia, Link, page, useForm } from '@inertiajs/svelte';
  import { writable } from 'svelte/store';
  import { onMount } from 'svelte';

  import ViewHeader from 'cmpt/app_ui/ViewHeader.svelte';
  import FormWrapper from 'cmpt/app_ui/FormWrapper.svelte';
  import FormSection from 'cmpt/app_ui/FormSection.svelte';
  import ActionMessage from 'cmpt/app_ui/ActionMessage.svelte';
  import EventHeader from '../Event/Partials/QuickEventHeader.svelte';

  // -----------------------------------------


   // export let message = $page.props.message;
   export let hash = $page.props.hash;
   export let eventData = $page.props.event;
   export let players = $page.props.playerList;
   const pageName = `Players list for ${eventData.title}`;
   const appName = $page.props.appName;

  // -----------------------------------------
  // The form processing code comes from inspector and is not touched
  let form = useForm({list: false});
  export let list = writable($page.props.playerList);

  // Adds modifications to playerList table for UI needs
  list.update((items) => {
    return items.map((item) => ({
          ...item,
          startsLate: (item.startsLate ? item.startsLate : 0), //converts false to explicit zero
          departsEarly_on: ((item.departsEarly && item.scheduled == 0) ? true : false), //field used for actual switch. The dual condition ensures that on save the possible "1" value is not lost
      })
    ); 
  });

  // console.log("Recieved list:")
  // console.log($page.props.playerList);

  // console.log("Processed list:")
  // console.log(list);


  // -----------------------------------------
  // Handle form submission
  function saveChanges() {

    list.update((items) => {
      return items.map((item) => ({
          ...item,
          startsLate: (item.startsLate ? item.startsLate : false), //converts zero back to explicit false
          departsEarly: (item.departsEarly_on ? 2 : item.departsEarly), // if field was "switched on", set a value, or keep whatever was there originally
          isEditing: undefined,
          departsEarly_on: undefined
        })
      ); 
    });

    // console.log("Processed list:")
    // console.log($list);

    $form.list = $list;
    // console.log($form.list)
    $form.post(route('event.quick.players', hash), {
      errorBag: 'updatePlayersList',
      preserveScroll: true,
    });
  }


  // -----------------------------------------
  // Implements table sorting by moving rows up/down one place at a time. 
  // This is MVP level solution, before implementing drag-and-drop
  function swapRows(index, direction) {
    let items = $list;
    if (
      (direction === 'up' && index > 0) || 
      (direction === 'down' && index < items.length - 1)
    ) {
      // Swap items in the array
      [items[index], items[index + (direction === 'up'? -1: 1)]] = 
        [items[index + (direction === 'up'? -1: 1)], items[index]];

      // Update the order property of the swapped items
      items[index].order = index;
      items[index + (direction === 'up' ? -1: 1)].order = index + (direction === 'up' ? -1: 1);

      // Trigger Svelte reactivity
      $list = items; 
    }
  }

  // -----------------------------------------
  onMount(() => {
   console.log(players);
   console.log(eventData);

  });
</script>
<svelte:head>
    <title>{pageName} > { appName }</title>
</svelte:head>













<div class="h-full">
  <EventHeader active="players" title="QuickEvent" />

  <ViewHeader>
    {pageName}
  </ViewHeader>
  <FormWrapper>
    <FormSection>
      <span slot="title">Player configuration</span>
      <span slot="description">
        Here will be instructions about how to use the settings available
      </span>

      <!-- Start the players table -->
      <svelte:fragment slot="formFields">
        
      <table class="table table-xs table-hover text-center">
        <thead>
          <tr>
            <th></th>
            <th>#</th>
            <th></th>
            <th>Name</th>
            <th>Skill &pm;</th>
            <th>Matches</th>
            <!-- <th title="onCourt / onBench">on/off</th> -->
            <th>isPaused</th>
            <th title="Can only set this before first match is scheduled!" >startsLate</th>
            <th title="Can only set this before first match is scheduled!">leavesEarly</th>
          </tr>
        </thead>
        <tbody>
          {#each $list as row, i (row.lid)}
          <tr class={` ${(row.isPaused || row.startsLate) ? 'text-neutral' : ''} `}>
            <td>
              <div class="join join-vertical">
                <button class="btn btn-ghost  btn-xs join-item" on:click={() => swapRows(i,'up')} disabled={i === 0}>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd" />
                  </svg>
                </button>
                <button class="btn btn-ghost btn-xs join-item" on:click={() => swapRows(i, 'down')} disabled={i === $list.length - 1}>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                  </svg>
                </button>
              </div>
            </td>
            <td>
              <span class="font-bold">{row.order}</span> 
            </td>
            <td>
              {#if row.startsLate }
              <span class="cursor-help" title="Player starts only from round #{row.startsLate}">⏱️</span>
              {/if} 
              {#if row.isPaused }
              <span class="cursor-help" title="Player on pause">⏸️</span>
              {/if}
              {#if row.departsEarly }
              <span class="cursor-help" title="Player needs to leave {row.departsEarly} rounds early">✈️</span>
              {/if} 
              
            </td>
            
            <td>
              <input type="text" placeholder="Unnamed player" class="input input-ghost w-full max-w-xs input-sm " bind:value={row.name}/>
            </td>
            
            <td class:text-error={row.skillLevel < 0}>
                <input type="range" min="-3" max="3" bind:value={row.skillLevel} class={`range range-xs max-w-20 ${row.skillLevel < 0 ? 'range-error': ''} ${row.skillLevel > 0 ? 'range-success':'range-info'}`} />
                <span>{row.skillLevel}</span>
            </td>


            <td>{row.played} / {row.scheduled}</td>
            <!-- <td>{row.onCourt} / {row.onBench}</td> -->
            <td>
            <input type="checkbox" class="toggle toggle-warning" bind:checked={row.isPaused}  />
            </td>
            <td>
              {#if !row.scheduled }
                <input type="range" min="0" max="2" bind:value={row.startsLate} class={`range range-xs max-w-12 ${row.startsLate > 0 ? 'range-warning': 'range-info'}`} />
                <span class="text-black">{row.startsLate}</span>
              {:else}
                  <span class={`font-bold ${row.startsLate > 0 ? "text-black" : ""}`}>{row.startsLate ? row.startsLate : ""}</span>
              {/if}

            </td>
            <td>
              {#if !row.scheduled }
                <input type="checkbox" class="toggle toggle-warning" bind:checked={row.departsEarly}  />
              {:else}
                <span class={`font-bold ${row.departsEarly > 0 ? "text-error" : ""}`}>{row.departsEarly  ? "Active" : ""}</span>
              {/if}
            </td>
          </tr>
          {/each}
        </tbody>
      </table>

      <!-- End table -->
      </svelte:fragment>

    <svelte:fragment slot="actions">
      {#if $form.recentlySuccessful }
      <ActionMessage style="success">
              Changes are saved
      </ActionMessage>
      {/if}
      <Link class="btn btn-info btn-outline mx-4" href={route('event.quick.home', eventData.hash)}>Close & return</Link>
      <button class="btn btn-primary" on:click={saveChanges} >Save changes</button>
    </svelte:fragment>

    </FormSection>
  </FormWrapper>

</div>

