<script context="module">
  import AdminLayout from 'lyo/AdminLayout.svelte';
  //import NestedLayout from 'lyo/NestedLayout.svelte';

  export const layout = [AdminLayout];
</script>

<script>
    import { inertia, Link, page } from '@inertiajs/svelte';
    import { onMount } from 'svelte';

    import dayjs from 'dayjs';
    import relativeTime from 'dayjs/plugin/relativeTime';
    import customParseFormat from 'dayjs/plugin/customParseFormat';
    import isToday from 'dayjs/plugin/isToday'
    dayjs.extend(customParseFormat);
    dayjs.extend(relativeTime);
    dayjs.extend(isToday);


     export let pagename = 'All QuickEvents';
	   export let appName = $page.props.appName;
     export let message = "Standard view loaded.";
     // export let hash = $page.props.hash;
     export let eventsList = $page.props.eventsList;
     let showMessage = false;

     onMount(() => {
        if($page.props.message) {
          showMessage = true;
        }

        // console.log("events");
        // console.log(eventsList[0]);


      });
</script>

<svelte:head>
    <title>{pagename} > { appName }</title>

    

</svelte:head>



  <main class="m-4">
  	<h2 class="text-2xl text-primary my-4 ml-8">{pagename}</h2>

    {#if $page.props.message}
    <div role="alert" class="alert hidden">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        class="stroke-info h-6 w-6 shrink-0">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
      </svg>
      <span>{$page.props.message}</span>
    </div>
    {/if}




    <h3 class="mt-6 mb-2 py-2 px-4 bg-base-200 hidden"><a id="#players">---</a></h3>

    <table class="table table-xs table-hover text-center border border-base-200">
        <thead>
          <tr class="text-center bg-base-200">
            <th>#</th>
            <th>Hash</th>
            <th>Title</th>
            <th>Dates</th>
            <th>Players</th>
            <th>Sessions</th>
            <th>Courts</th>
            <th>Owner user</th>
            <th class="text-right">Updated</th>
            <th class="text-right">Created</th>
          </tr>
        </thead>
        <tbody>
          {#each eventsList as item,i}
          <tr class={`hover ${dayjs(item.date_begins).isToday() ? '' : 'text-neutral'}`}>
            <th>{item.id}</th>
            <td
              class={`text-center}`}
            ><a class={`link text-sm ${dayjs(item.date_begins).isToday() ? 'link-primary' : ''}`} href="{route('event.quick.home', item.hash)}">{item.hash}</a></td>
            <td><Link class="link link-primary"  href={route('pub.qe.home',item.hash)}>{item.title}</Link></td>
            <td><span>{dayjs(item.date_begins).format('DD.MM.YYYY')}</span> <span class={`${item.date_ends == null ? 'hidden' : ''}`}>/ {item.date_ends}</span></td>
            <td class="">{item.playerCount}</td>
            <td class="">
              {#each item.sessions as shash}
                <Link href={route('debug.session.inspect', shash)} class="link"> {shash}</Link> |
              {/each}
            </td>
            <td class="">{item.courts}</td>
            <td class="">{item.owner_name} <span class="text-neutral" >({item.owner_id})</span></td>
            <td class="text-right"><span class="text-xs" title="{item.updated}">{item.diff_updated}</span></td>
            <td class="text-right"><span class="text-xs" title="{item.diff_created}">{item.created}</span></td>

          </tr>
          {/each}
        </tbody>
      </table>



  </main>