<script context="module">
  import SiteLayout from 'lyo/SiteLayout.svelte';
  
  //import NestedLayout from 'lyo/NestedLayout.svelte';

  export const layout = [SiteLayout];

</script>

<script>
    import { inertia, Link, page, router } from '@inertiajs/svelte';
    import { onMount } from 'svelte';

    
     export let pagename = 'This is test view';
	   export let appName = $page.props.appName;
     export let userName = $page.props.auth.user?.name;

     const showDash = () => {
      router.get(route('app.home'))
      console.log("Test button clicked")
     };

     onMount(() => {
      
      });
</script>

<svelte:head>
    <title>{pagename} > { appName }</title>
</svelte:head>



  <main>
  	<div class="hero min-h-screen" style="background-image: url(/img/hero-0.jpg);">
      <div class="hero-overlay bg-opacity-70"></div>
      <div class="hero-content text-center text-neutral-content">
        <div class="max-w-md">
          <h1 class="mb-5 text-2xl font-bold">{pagename}</h1>
          <p class="text-lg"> Logged in as: {userName} </p>
          
          <button class="my-4 btn btn-primary" on:click={showDash}>Dashboard</button>
        </div>
      </div>
    </div>

  </main>