<script>
  import { inertia,  page, useForm } from '@inertiajs/svelte';
  import { onMount } from 'svelte';


  import FormSection from 'cmpt/app_ui/FormSection.svelte';


  export let playerNames,playerCount,ordered;
  export let min, max;

  let nextId = 1; 
  let maxNames = (max >= min && max < 50) ? max : 50;
  let minNames = (min >= 5) ? min : 6;
  let timeLabel = "";

  onMount(() => {
        nextId = playerCount+1;
        console.log("AddPlayersForm loaded:")
        console.log(`Players ${playerCount}`)
        console.log(playerNames)
        console.log(`order: ${ordered}`)
    });


  // ========================================================
  // let form = useForm({
  //   nameList: [],
  //   count: 0,
  // });


  // ========================================================
  function setNames() {

    // tempList = [];
    console.log(`SetNames => Players: ${playerNames.length}`);
    console.log(playerNames);
    console.log(`order: ${ordered}`)

    // playerNames.forEach((entry)=>{
    //     //if(entry.name == "") entry.name = "EMPTY"
    //     tempList.push(entry.name);
    //   });
    // playerCount = tempList.length;
    // playerNames = tempList;
  }



  // ========================================================
  function addName() {
    console.log("before add");
    console.log(playerNames);

    playerNames.push({id: nextId++, name: '', uniq: false, empty: true});
    playerNames = playerNames; // Svelte reactive trigger needs this
    playerCount = playerNames.length;
    console.log(`Name slot added ${nextId} / ${playerCount}`);
    console.log(playerNames);
  }

  // ========================================================
  function removeName(index) {
      let newlist = playerNames;
      newlist.splice(index, 1);
      playerNames = newlist;
      console.log(`New list after remove of ${index}:`)
      console.log(newlist)
      
    }

  // ========================================================
  // Reactive statements

  $: if($page.props) {
      // console.log(`App name loaded: ${$page.props.appName}`);
      // console.log(players)
    }


    // ======================================================
</script>


<FormSection>
	
  <span slot="title">
     Player names
  </span>
  <span slot="description">
          Put in the player names. Empty fields will be discarded on save. If players should be treated as ranked in order of their strength, check the box on the bottom of list. Current settings allow {min} to {max} players
  </span>

  <!-- Start form fields -->
  <svelte:fragment slot="formFields">      

    {#each playerNames as entry, i (entry.id)}

    <div class="join mb-2 w-full">
      <input class="input input-bordered join-item w-16 input-md text-center " value="{i+1}." readonly />
      <input class="input input-bordered join-item w-full input-md" placeholder="Player name #{i+1}" bind:value={entry.name} />
      <button class="btn btn-info btn-outline hover:btn-error bold join-item btn-md" title="Remove this name" value="&mdash;" on:click|preventDefault={()=>removeName(i)} disabled={playerNames.length == minNames} ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path d="M11 1.75V3h2.25a.75.75 0 0 1 0 1.5H2.75a.75.75 0 0 1 0-1.5H5V1.75C5 .784 5.784 0 6.75 0h2.5C10.216 0 11 .784 11 1.75ZM4.496 6.675l.66 6.6a.25.25 0 0 0 .249.225h5.19a.25.25 0 0 0 .249-.225l.66-6.6a.75.75 0 0 1 1.492.149l-.66 6.6A1.748 1.748 0 0 1 10.595 15h-5.19a1.75 1.75 0 0 1-1.741-1.575l-.66-6.6a.75.75 0 1 1 1.492-.15ZM6.5 1.75V3h3V1.75a.25.25 0 0 0-.25-.25h-2.5a.25.25 0 0 0-.25.25Z"></path></svg></button>
    </div>
    {/each}

    <div class="flex justify-end">
      <button class="btn btn-outline btn-primary btn-sm mt-2 ml-auto " on:click|preventDefault={addName} disabled={playerNames.length == maxNames}>+ add more</button>
    </div>

    <div class="form-control max-w-md">
      <label class="label cursor-pointer">
        <span class="label-text">Order of names represent individual strength of players </span>
        <input type="checkbox" class="toggle toggle-primary" bind:checked={ordered} />
      </label>
    </div>

    

  </svelte:fragment>
  <!-- <svelte:fragment slot="actions">
    {#if false }
    <ActionMessage style="success">
            Profile updated
    </ActionMessage>
    {/if}
    <button class="btn btn-secondary btn-outline" on:click|preventDefault={setNames} >Confirm names list</button>
</svelte:fragment> -->

</FormSection>