<script context="module">
  import SiteLayout from 'lyo/SiteLayout.svelte';
  //import NestedLayout from 'lyo/NestedLayout.svelte';

  export const layout = [SiteLayout];
</script>

<script>
  import { inertia, Link, page, router, useForm } from '@inertiajs/svelte';
  import { onMount } from 'svelte';

	export let pagename = 'Start new - 01';
  export let appName = $page.props.appName;
  export let  players = [
                      {id: 1, name: '', uniq: false, empty: false},
                      {id: 2, name: '', uniq: false, empty: false},
                      {id: 3, name: '', uniq: false, empty: false},
                      {id: 4, name: '', uniq: false, empty: false},
                      {id: 5, name: '', uniq: false, empty: false},
                      {id: 6, name: '', uniq: false, empty: false},
                      {id: 7, name: '', uniq: false, empty: false},
                      {id: 8, name: '', uniq: false, empty: false},
                      {id: 9, name: '', uniq: false, empty: false},
                      {id: 10, name: '', uniq: false, empty: false},
                      {id: 11, name: '', uniq: false, empty: false},
                      {id: 12, name: '', uniq: false, empty: false},
                      {id: 13, name: '', uniq: false, empty: false}
                      ];
  let nextId = 14;
  let maxNames = 18;
  let minNames = 6;



  // ========================================================
  let form = useForm({
    nameList: [],
    count: 0,
  });


  // ========================================================
  function sendNames() {
    
    $form.nameList = [];

    players.forEach((entry)=>{
        //if(entry.name == "") entry.name = "EMPTY"
        $form.nameList.push(entry.name);
      });
    $form.count = players.length

    console.log("sending names list...")
    $form.get(route("debug.session.generate", players.length));
  }



  // ========================================================
  function addName() {
    players.push({id: nextId++, name: ''});
    players = players
    //console.log(`Name slot added ${nextId} / ${players.length}`);
  }

  // ========================================================
  function remove(index) {
      let newlist = players
      newlist.splice(index, 1);
      players = newlist
      //console.log(`New list after remove of ${index}:`)
      //console.log(newlist)
      
    }

  // ========================================================
  // Reactive statements

  $: if($page.props) {
      // console.log(`App name loaded: ${$page.props.appName}`);
      // console.log(players)
    }


    // ======================================================
</script>


<svelte:head>
    <title>{pagename} > { appName }.</title>
</svelte:head>



  <main>
  	<div class="hero min-h-screen" style="">
      
      <div class="hero-content text-center">
        <div class="max-w-md">
          <h1 class="mb-5 text-5xl font-bold">{pagename}</h1>


          <form on:submit|preventDefault={sendNames}>
            <label>Player names</label>

            {#each players as entry, i (entry.id)}

              <div class="block mb-2">{i+1}.
                <input type="text" placeholder="Player name #{i+1}" class="input input-bordered input-primary focus:text-primary max-w-sm" bind:value={entry.name}/>
                <input type="button" class="btn btn-outline hover:btn-error btn-xs bold" title="remove from list" value="&mdash;" on:click|preventDefault={()=>remove(i)} disabled={players.length == minNames} />
              </div>

            {/each}

            <button class="btn btn-outline btn-primary btn-sm" on:click|preventDefault={addName} disabled={players.length == maxNames}>+ add more</button>


            <div class="pt-4 mt-8 border-0 border-t border-secondary">
              <button class="btn btn-secondary block mx-auto" on:click|preventDefault={sendNames} >Continue</button>

            </div>
          </form>



        </div>
      </div>
    </div>

  </main>