<script context="module">
  import SiteLayout from 'lyo/SiteLayout.svelte';
  // import NestedLayout from 'lyo/NestedLayout.svelte';

  export const layout = [SiteLayout];
</script>

<script>
  import { inertia, page, useForm, Link } from '@inertiajs/svelte';
  import { onMount } from 'svelte';
  
  export let errors;

  const form = useForm({
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
    terms: false,
  });

  function sendForm () {

    $form.post(route('register'), {
        onFinish: () => {
          $form.reset('password', 'password_confirmation')
        },
    });

  }


  // ######################################
  $: if(false) {


  }

  // ######################################
  onMount(() => {

    
  });

</script>

<svelte:head>
    <title>Create your user account</title>

    

</svelte:head>



<section>
  <div class="flex w-full max-w-sm mx-auto overflow-hidden bg-white rounded-lg shadow-lg dark:bg-gray-800 lg:max-w-4xl mt-4 lg:mt-8">
    <div class="flex w-full flex-col px-6 py-8 md:px-8 lg:w-1/2">
      <div class="flex justify-center mx-auto">
        <img class="h-auto w-2/3" src="/img/logo_playminton_tr2.png" alt="">
      </div>
      <h3 class="my-3 text-2xl text-center text-primary">Start your journey!</h3>

      <div class="form-control">
        <label class="label">
          <span class="label-text">Name</span>
        </label>
        <input type="text" placeholder="Your name" class="input input-bordered {$form.errors.name ? 'input-error' : ''}" bind:value={$form.name} />
        {#if $form.errors.name}
        <label>
          <span class="label-text-alt text-error">{$form.errors.name}</span>
        </label>
        {/if}
      </div>

      <div class="form-control">
        <label class="label">
          <span class="label-text">Email</span>
        </label>
        <input type="text" placeholder="email" class="input input-bordered {$form.errors.email ? 'input-error' : ''}" bind:value={$form.email} />
        {#if $form.errors.email}
        <label>
          <span class="label-text-alt text-error">{$form.errors.email}</span>
        </label>
        {/if}
      </div>
      <div class="form-control">
        <label class="label">
          <span class="label-text">Password</span>
        </label>
        <input type="password" placeholder="" class="input input-bordered {$form.errors.password ? 'input-error' : ''}" bind:value={$form.password} />
        {#if $form.errors.password}
        <label>
          <span class="label-text-alt text-error">{$form.errors.password}</span>
          <span class="label-text-alt"><Link href="#" class="link link-hover link-primary">Forgot password?</Link></span>
        </label>
        {/if}
      </div>
      <div class="form-control">
        <label class="label">
          <span class="label-text">Repeat password</span>
        </label>
        <input type="password" placeholder="" class="input input-bordered {$form.errors.password_confirmation ? 'input-error' : ''}" bind:value={$form.password_confirmation} />
        {#if $form.errors.password_confirmation}
        <label>
          <span class="label-text-alt text-error">{$form.errors.password_confirmation }</span>
        </label>
        {/if}
      </div>
      
      <div class="form-control mt-6">
        <button class="btn btn-primary text-lg" on:click={sendForm}>Register</button>
      </div>

    </div>
    <!-- Right side image -->
    <div class="hidden bg-cover lg:block lg:w-1/2 bg-center opacity-60" style="background-image: url('/img/bg_sec_25.jpg');"></div>
  </div>
</section>


  	<!-- <div class="hero min-h-screen bg-base-100">
      <div class="hero-content flex-col lg:flex-row-reverse">
        <div class="text-center lg:text-left pl-4 lg:pl-12 max-w-2xl">
          <h1 class="xl:text-5xl text-lg md:text-3xl font-bold">Start your journey</h1>
          <p class="py-6">Some nice text here</p>
        </div>
        <div class="card flex-shrink-0 w-full max-w-md shadow-2xl bg-base-100">
          <div class="card-body">
            
          </div>
        </div>
      </div>
    </div> -->
