<script context="module">
  import AdminLayout from 'lyo/AdminLayout.svelte';
  //import NestedLayout from 'lyo/NestedLayout.svelte';

  export const layout = [AdminLayout];
</script>

<script>
    import { inertia, Link, page } from '@inertiajs/svelte';
    import { onMount } from 'svelte';

    
     export let pagename = 'Public routes defined';
	   export let appName = $page.props.appName;
     export let message = "Standard view loaded.";
     export let hash = $page.props.hash;
     export let routeList = $page.props.routeList;
     let showMessage = false;

        console.log($page.props);
     
     onMount(() => {
        if($page.props.message) {
          showMessage = true;
        }

        // console.log(matchTable);


      });
</script>

<svelte:head>
    <title>{pagename} > { appName }</title>

    

</svelte:head>



  <main class="m-4">
  	<h2 class="text-2xl text-primary my-4 ml-8">All routes implemented for user UI</h2>

    {#if $page.props.message}
    <div role="alert" class="alert hidden">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        class="stroke-info h-6 w-6 shrink-0">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
      </svg>
      <span>{$page.props.message}</span>
    </div>
    {/if}




    <h3 class="mt-6 mb-2 py-2 px-4 bg-base-200 hidden"><a id="#players">---</a></h3>

    <table class="table table-xs table-hover border border-base-200">
        <thead>
          <tr class="text-center bg-base-200">
            <th>#</th>
            <th>name</th>
            <th>URL</th>
            <th>NameSpace</th>
          </tr>
        </thead>
        <tbody>
          {#each routeList as item,i}
          <tr class={`hover`}>
            <th>{i}</th>
            <td
              class={`text-left}`}
            ><Link class={`link text-sm ${item.name.startsWith('debug.') ? 'link-secondary' : 'link-primary'}`} href="{route(item.name, hash)}" target="_blank" rel="noreferrer">{item.name}</Link></td>
            <td class="text-neutral text-xs">{item.uri}</td>
          </tr>
          {/each}
        </tbody>
      </table>



  </main>