<script context="module">
  import SiteLayout from 'lyo/AppLayout.svelte';
  //import NestedLayout from 'lyo/NestedLayout.svelte';

  export const layout = [SiteLayout];
</script>

<script>
  import { inertia, Link, page, router, useForm } from '@inertiajs/svelte';
  import { onMount, onDestroy } from 'svelte';
  // import { slide, fade } from 'svelte/transition';

  import ViewHeader from 'cmpt/app_ui/ViewHeader.svelte';
  import ModalForm from 'cmpt/app_ui/ModalForm.svelte';
  import FormWrapper from 'cmpt/app_ui/FormWrapper.svelte';
  import FormSection from 'cmpt/app_ui/FormSection.svelte';
  import MatchList from 'cmpt/app_ui/MatchListTable.svelte';
  import EventHeader from './Partials/QuickEventHeader.svelte';
  // -----------------------------------------


  // import Card from 'cmpt/app_ui/Card.svelte';
  // import CardGrid from 'cmpt/app_ui/CardGrid.svelte';
  // import ManagePlayersForm from './Partials/ManagePlayersForm.svelte';

  import dayjs from 'dayjs';
  import relativeTime from 'dayjs/plugin/relativeTime';
  import customParseFormat from 'dayjs/plugin/customParseFormat';
  import isToday from 'dayjs/plugin/isToday'
  dayjs.extend(customParseFormat);
  dayjs.extend(relativeTime);
  dayjs.extend(isToday);

  // -----------------------------------------

  // const minPlayers = 6;

  export let eventData = $page.props.event;
  export let sessionData = $page.props.session;
  export let playerList = $page.props.playerList;
  export let matchList = $page.props.matchList;

  export let pageName = "Matchup scheduling";
  export let appName = $page.props.appName;
 //  export let public_url = route('pub.qe.home', eventData.hash);
	export let hash = eventData.hash;
  export let modalFrame;

  // ----------------------------------
  function courtsToUse() {
    return sessionData.courtStats.useCourts;
  }


// ----------------------------------
  function resetSchedule() {
    console.log("reset schedule called")
    router.post(route('event.schedule.reset', sessionData.hash));
    return true;
  }


  // ----------------------------------
  function openScoreModal(event) {
    //console.log('Event received from matchlist:', event.detail.match);
    let url = route('event.match.scoreform', { sessionHash: sessionData.hash, matchId: event.detail.match});

    modalFrame.src = url;
    scoreFormDialog.showModal();
    
    //console.log(url);

    return true;
  }

  // ----------------------------------
  function closeScoreModal(event) {
    scoreFormDialog.close();
    setTimeout(() => { 
      modalFrame.src = 'about:blank'
    }, 1000); // unloads Iframe content
    router.reload({ 
      onStart: () => console.log("starting reload..."),
      onSuccess: () => console.log("reloaded"),
      only: ['matchList'],
      replace: true
    });
  }

  // ========================================================
  let form = useForm({
    nameList: [],
    count: 0,
  });


  
  // ========================================================
  // Reactive statements

  $: if(false) {
      // console.log(`text`);
      // console.log(appName);
    }


  // ======================================================
  onMount(() => {
    console.log("Scheduling:")
    // console.log(route('event.matches.addround', sessionData.hash));
    console.log(sessionData.hash);


    modalFrame = document.getElementById("scoreFrame");

    // For closeModal calls
    window.addEventListener('message', closeScoreModal);
    // console.log(sessionData.hash);

  });

  onDestroy(() => {
    window.removeEventListener('message', closeScoreModal);
  });
</script>
<svelte:head>
    <title>{pageName} > { appName }.</title>
</svelte:head>












<div class="h-full">
  <EventHeader active="scheduling" title="{pageName}" />

  <!-- <ViewHeader>
    QuickEvents
  </ViewHeader> -->

  <FormWrapper>
  <FormSection>
    <span slot="title">
      Scheduling parameters
    </span>
    <span slot="description">
      <p>Matches scheduled: {sessionData.statistics.matchesScheduled}</p>
      <p>Matches finished: {sessionData.statistics.matchesPlayed}</p>
      <p>Round on court: {sessionData.statistics.currentRound}</p>
    </span>

    <!-- Start form fields -->
    <svelte:fragment slot="formFields">
      <div class="stats stats-vertical lg:stats-horizontal shadow">
        <div class="stat">
          <div class="stat-title">Players</div>
          <div class="stat-value">{sessionData.availablePlayers } <span class="text-neutral {sessionData.availablePlayers < playerList.length ? '' : 'hidden'}">/{playerList.length}</span></div>
          <div class="stat-desc">available for next round</div>
        </div>

        <div class="stat">
          <div class="stat-title">Courts</div>
          <div class="stat-value">{courtsToUse()}</div>
          <div class="stat-desc">to be used for next round</div>
        </div>

        <div class="stat">
          <div class="stat-title">Scheduled</div>
          <div class="stat-value">{sessionData.statistics.matchesScheduled}</div>
          <div class="stat-desc">matches so far</div>
        </div>
      </div>
    </svelte:fragment>

  </FormSection>



  <div class="divider"></div>



  <FormSection>
    <span slot="title">
      Actions
    </span>
    <span slot="description">
      <ul class="list-disc">
        <li>Schedule next round</li>
        <li>Set custom court number</li>
        <li>Schedule single next match </li>
        <li></li>
        <li>RESET all schedule (via confirm)</li>
      </ul>
    </span>

    <!-- Start form fields -->
    <svelte:fragment slot="formFields">
      <div>
        <button class="btn btn-primary text-nowrap" use:inertia={{ href: route('event.matches.addround', sessionData.hash, courtsToUse() ), method: 'post' }}>Add one round of matches</button>
        <span class="text-info pl-2">assign one match per court ({sessionData.courtStats.useCourts} total)</span>
      </div>
      <div>
        <button class="btn btn-error btn-outline my-4" on:click={resetSchedule}>Reset ALL</button>
        <span class="text-info pl-2">deletes ALL scheduled matches from this event</span>
      </div>
    </svelte:fragment>
    <!-- <svelte:fragment slot="actions">
      {#if false }
      <ActionMessage style="success">
              Successful update
      </ActionMessage>
      {/if}
      <button class="btn btn-secondary btn-outline" on:click|preventDefault={false}>Demo button</button>
    </svelte:fragment> -->

  </FormSection>




  <div class="divider"></div>




  <FormSection>
    <span slot="title">
      List of matches
    </span>
    <span slot="description">
      List of scheduled matches
    </span>

    <!-- Start form fields -->
    <svelte:fragment slot="formFields">
      <MatchList scoreable="true" orgMode="true" on:openScoreForm={openScoreModal} matchList={matchList} sessionHash={sessionData.hash} /> 
    </svelte:fragment>
    <svelte:fragment slot="actions">
      {#if false }
      <ActionMessage style="success">
              Successful update
      </ActionMessage>
      {/if}
      <Link class="btn btn-info btn-outline" href={route('event.quick.home',eventData.hash)}>Close and return</Link>
    </svelte:fragment>

  </FormSection>
  </FormWrapper>


  <dialog id="scoreFormDialog" class="modal">
    <div class="modal-box min-w-sm h-full">
      <form method="dialog">
        <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
      </form>
      <iframe id="scoreFrame" src="" class="w-full h-full"></iframe>
    </div>
  </dialog>

</div>