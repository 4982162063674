<script context="module">
  import SiteLayout from 'lyo/AppLayout.svelte';
  //import NestedLayout from 'lyo/NestedLayout.svelte';

  export const layout = [SiteLayout];
</script>

<script>
  import { inertia, Link, page, router, useForm } from '@inertiajs/svelte';
  import { onMount } from 'svelte';
  // import { slide, fade } from 'svelte/transition';

  import ViewHeader from 'cmpt/app_ui/ViewHeader.svelte';
  import Card from 'cmpt/app_ui/Card.svelte';
  import CardGrid from 'cmpt/app_ui/CardGrid.svelte';
  import CodeBlock from 'cmpt/app_ui/CodeBlock.svelte';
  import ModalForm from 'cmpt/app_ui/ModalForm.svelte';
  import EventHeader from './Partials/QuickEventHeader.svelte';
  // import ManagePlayersForm from './Partials/ManagePlayersForm.svelte';

  import dayjs from 'dayjs';
  import relativeTime from 'dayjs/plugin/relativeTime';
  import customParseFormat from 'dayjs/plugin/customParseFormat';
  import isToday from 'dayjs/plugin/isToday'
  dayjs.extend(customParseFormat);
  dayjs.extend(relativeTime);
  dayjs.extend(isToday);


  const minPlayers = 6;

  export let eventData = $page.props.event;
  export let sessionData = $page.props.session;
  export let pageName = eventData.name;
  export let appName = $page.props.appName;
  export let public_url = route('pub.qe.home', eventData.hash);
	export let hash = eventData.hash;
  export let playerNames = getPlayers();
  export let enoughPlayers = playerNames.length >= minPlayers;
  export let areScheduled = sessionData.statistics.matchesScheduled || false;

  // ========================================================
  function showDate(myDate) {
    let show = '';

    if(dayjs(eventData.date_begins).isToday()) {
      show = "today";
    } else {
      show = dayjs(eventData.date_begins).format('DD.MM.YY');
    }
    return show;
  }


  // ----------------------------------
  function getPath(input) {
    const url = new URL(input);
    return url.pathname;
  }

  // ----------------------------------
  function addPlayerName() {
    console.log("Player name adding not implemented yet");
  }


  // ----------------------------------
  // Assigns names to special variable to assist Player card display
  function getPlayers() {
    // console.log("getPlayers called:");
    
    return $page.props.playerList.map(row => {
      const name = row.name;
      const isPaused = (row.isPaused || row.startsLate > 0) ? true : false;
      return { name, isPaused};
    });
  }

  // ========================================================
  let form = useForm({
    nameList: [],
    count: 0,
  });


  
  // ========================================================
  // Reactive statements

  $: if(false) {
      // console.log(`text`);
      // console.log(appName);
    }


  // ======================================================
  onMount(() => {
    // console.log($page.props.session);
    // console.log("Event:")
    // console.log(eventData);
    // console.log(areScheduled);

  });
</script>
<svelte:head>
    <title>{pageName} > { appName }.</title>
</svelte:head>












<div class="h-full">
  <EventHeader active="overview" title="QuickEvents" />

  <!-- <ViewHeader>
    
  </ViewHeader> -->

  <CardGrid>
    <Card>
      <svelte:fragment slot="cardTitle">
        Event Overview
      </svelte:fragment>
      <svelte:fragment slot="cardContent">
        <p class="font-bold">&quot;{eventData.name}&quot;</p>
        <p class="">Date: {showDate(eventData.date_begins)}</p>
        <p class="">Location: <i>[not set]</i></p>
        <p class="">Event access link:</p>
        <CodeBlock text={public_url}>{public_url}</CodeBlock>
      </svelte:fragment>
      <svelte:fragment slot="cardActions">
        <button class="btn btn-secondary btn-outline btn-disabled">Change event info</button>
      </svelte:fragment>
    </Card>



    <Card>
      <svelte:fragment slot="cardTitle">
        Players
      </svelte:fragment>
      <svelte:fragment slot="cardContent">

        {#if playerNames.length}
        <ul class="menu w-full pl-0 ">
        <li>
          <details>
            <summary class="pl-2 border">{playerNames.length >= minPlayers ? 'Currently' : 'Only'} {playerNames.length} players enrolled</summary>
            <ol class="bg-base-100 ml-4">
              {#each playerNames as row, i}
              <li class:text-neutral={row.isPaused}>{i+1}. {row.name}</li>
              {/each}
            </ol>
          </details>
        </li>
        </ul>
        {:else}
        <p>No players enrolled yet.</p> <p class="text-error">This event needs minimum {minPlayers} to be playable.</p>
        {/if}
        

      </svelte:fragment>
      <svelte:fragment slot="cardActions">
        <Link href={route('event.quick.players', hash)} class="btn btn-secondary {enoughPlayers ? 'btn-outline' : ''}">
        {enoughPlayers ? 'Manage playerlist' : 'Enroll more players' }</Link>
      </svelte:fragment>
    </Card>



    <Card>
      <svelte:fragment slot="cardTitle">
        Scheduling
      </svelte:fragment>
      <svelte:fragment slot="cardContent">
        {#if areScheduled != 0}
          <div class="stat shadow">
            <div class="stat-title">Matches scheduled</div>
            <div class="stat-value">{areScheduled}</div>
            <!-- <div class="stat-desc">are scheduled</div> -->
          </div>
        {:else}
          <p class="badge badge-warning text-white" >Nothing is scheduled yet.</p>

          <p class="my-4"><span class="badge badge-info badge-outline">Remember</span> No new players can be added after scheduling is started!</p>
        {/if}


        {#if (!enoughPlayers) }
        <p class="text-error">Minimum number of {minPlayers} players is needed to start scheduling.</p>
        {/if}
      </svelte:fragment>
      <svelte:fragment slot="cardActions">
        <Link href={route('event.quick.scheduling', hash)} class="btn btn-secondary btn-outline" disabled={enoughPlayers ? undefined : true}>Open scheduling</Link>
      </svelte:fragment>
    </Card>

  </CardGrid>

  </div>