import '../css/app.css';

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

import { createInertiaApp } from '@inertiajs/svelte';


createInertiaApp({
  id: 'svlt',
  resolve: name => {
  	// console.log(`Requesting page: ${name}`)

    const pages = import.meta.glob('./pages/**/*.svelte', { eager: true })
    return pages[`./pages/${name}.svelte`]
  },
  setup({ el, App, props }) {
    new App({ target: el, props })
  },
})