<script>
	import { inertia, Link, page, router } from '@inertiajs/svelte';

  import MatchList from 'cmpt/app_ui/MatchListTable.svelte';

	export let matchList = $page.props.matches;
	export let canScore = $page.props.canAddScores;
	export let shash = $page.props.session.hash;

	// console.log("Tab for Matches loaded.")
	// console.log($page.props.matches[0])
	// console.log($page.props.matches[0].lid)
	// console.log($page.props.session)
	// console.log(shash)
</script>

<div role="tabpanel" {...$$restProps}>
	<MatchList scoreable={canScore} matchList={matchList} sessionHash={shash} />
</div>