<script>

/* 
 * This component creates a layout wrapper
 * for FormSection components.
 * This is purely layout helper component.
 * 
 */

const doNothing = () => {};

</script>

<div>
    <div class="max-w-6xl mx-auto py-10 sm:px-6 lg:px-8 flex flex-col border-opacity-50">
        <slot>This Form wrapper expects some content inside</slot>
    </div>
</div>

