<script context="module">
  import SiteLayout from 'lyo/AppLayout.svelte';
  export const layout = [SiteLayout];

</script>

<script>
    import { inertia, Link, page, router } from '@inertiajs/svelte';
    import { onMount } from 'svelte';

    import UpdateProfileInformationForm from './Partials/UpdateProfileInformationForm.svelte';
    import LogoutOtherBrowserSessionsForm from './Partials/LogoutOtherBrowserSessionsForm.svelte';
    import DeleteUserForm from './Partials/DeleteUserForm.svelte';
    import UpdatePasswordForm from './Partials/UpdatePasswordForm.svelte';
    // import UpdateProfileInformationForm from './Partials/UpdateProfileInformationForm.svelte';
    import ViewHeader from 'cmpt/app_ui/ViewHeader.svelte';
    import DialogModal from 'cmpt/app_ui/DialogModal.svelte';
    import ActionMessage from 'cmpt/app_ui/ActionMessage.svelte';
    import FutureFeature from 'cmpt/app_ui/FutureFeature.svelte';
    
    
    export let pagename = 'User profile';
    export let appName = $page.props.appName;
    export let js = $page.props.jetstream;

    onMount(() => {
        console.log("User profile view loaded:")
        console.log($page);

    });

    
</script>

<svelte:head>
    <title>{pagename} > { appName }</title>
</svelte:head>


<div class="h-full">
    <ViewHeader>
            Empty Profile
    </ViewHeader>

    <div>
        <div class="max-w-6xl mx-auto py-10 sm:px-6 lg:px-8 flex flex-col border-opacity-50">
            {#if (js.canUpdateProfileInformation)}
            <UpdateProfileInformationForm />
            {/if}

            {#if (js.canUpdatePassword)}
            <div class="divider"></div>
            <UpdatePasswordForm />
            {/if}

            {#if (js.canManageTwoFactorAuthentication)}
            <div class="divider"></div>
            <FutureFeature stage="lock" withIcon>
                <span slot="title">Two-Factor authentication</span>
                <span slot="description">This feature will be included in future premium plans      </span>
            </FutureFeature>
            {/if}

            {#if (js.canManageTwoFactorAuthentication)}
            <div class="divider"></div>
            <LogoutOtherBrowserSessionsForm />
            {/if}

            {#if (js.hasAccountDeletionFeatures)}
            <div class="divider"></div>
            <DeleteUserForm />
            
            {/if}
        </div>
    </div>
</div>
