<script context="module">
  import AdminLayout from 'lyo/AdminLayout.svelte';
  //import NestedLayout from 'lyo/NestedLayout.svelte';

  export const layout = [AdminLayout];
</script>

<script>
    import { inertia, Link, page } from '@inertiajs/svelte';
    import { onMount } from 'svelte';

    
     export let pagename = 'All Sessions';
	   export let appName = $page.props.appName;
     export let message = "Standard view loaded.";
     // export let hash = $page.props.hash;
     export let sessionsList = $page.props.sessionsList;
     let showMessage = false;

     onMount(() => {
        if($page.props.message) {
          showMessage = true;
        }

        // console.log("matchTable");
        console.log(sessionsList[0]);


      });
</script>

<svelte:head>
    <title>{pagename} > { appName }</title>

    

</svelte:head>



  <main class="m-4">
  	<h2 class="text-2xl text-primary my-4 ml-8">All sessions</h2>

    {#if $page.props.message}
    <div role="alert" class="alert hidden">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        class="stroke-info h-6 w-6 shrink-0">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
      </svg>
      <span>{$page.props.message}</span>
    </div>
    {/if}




    <h3 class="mt-6 mb-2 py-2 px-4 bg-base-200 hidden"><a id="#players">---</a></h3>

    <table class="table table-xs table-hover text-center border border-base-200">
        <thead>
          <tr class="text-center bg-base-200">
            <th>ID</th>
            <th>Hash</th>
            <th>Event</th>
            <th>Players</th>
            <th>Matches</th>
            <th>Owner</th>
            <th>Updated</th>
            <th>Created</th>
          </tr>
        </thead>
        <tbody>
          {#each sessionsList as item,i}
          <tr class={`hover ${item.scheduled ? '' : ''}`}>
            <th>{item.id}</th>
            <td
              class={`text-center}`}
            ><a class={`link text-sm ${item.scheduled ? 'link-primary' : 'link-neutral'}`} href="{route('debug.session.inspect', item.hash)}">{item.hash}</a></td>
            <td><span class="text-info">&quot;{item.event.name}&quot;</span> <Link class="link link-info" href={route('event.quick.home', item.event.hash)}>({item.event.hash})</Link></td>
            <td>{item.players}</td>
            <td><span class={`${item.played ? '' : 'text-neutral'}`}>{item.played}</span> / {item.scheduled}</td>
            <td class="text-neutral text-xs">{item.owner.name}({item.owner.id})</td>
            <td class="text-right"><span class="text-xs" title="{item.updated}">{item.diff_updated}</span></td>
            <td class="text-right"><span class="text-xs" title="{item.diff_created}">{item.created}</span></td>

          </tr>
          {/each}
        </tbody>
      </table>



  </main>