<script context="module">
  import AppLayout from 'lyo/AppLayout.svelte';
  export const layout = [AppLayout];
</script>

<script>
    import { inertia, Link, page } from '@inertiajs/svelte';
    import { onMount } from 'svelte';

    import ViewHeader from 'cmpt/app_ui/ViewHeader.svelte';
  // -----------------------------------------

    import dayjs from 'dayjs';
    import relativeTime from 'dayjs/plugin/relativeTime';
    import customParseFormat from 'dayjs/plugin/customParseFormat';
    import isToday from 'dayjs/plugin/isToday'
    dayjs.extend(customParseFormat);
    dayjs.extend(relativeTime);
    dayjs.extend(isToday);
  // -----------------------------------------


    export let pageName = 'My QuickEvents';
    export let appName = $page.props.appName;
    export let eventsList = $page.props.eventsList;

  // -----------------------------------------
  onMount(() => {
    //console.log(eventsList)
  });
</script>

<svelte:head>
    <title>{pageName} > { appName }</title>
</svelte:head>



<div class="h-full">
  <ViewHeader>
    {pageName}
  </ViewHeader>

  <div class="max-w-3xl mx-auto">
    <h3 class="text-primary font-bold my-2">Practices</h3>
    <table class="table table-xs text-center border-b border-base-300 max-w-xl">
      <thead>
        <tr class="text-center  bg-base-200">
          <th>#</th>
          <th>Hash</th>
          <th class="text-left">Title</th>
          <th>Players</th>
          <th class="text-right">Updated</th>
          <!-- <th class="text-right">Created</th> -->
        </tr>
      </thead>
      <tbody>
        {#each eventsList as item,i}
        {#if item.type == 'P'}
        <tr class={`${dayjs(item.date_begins).isToday() ? 'font-bold' : ''}`}>
          <th>{i+1}</th>
          <td
            class={`text-center}`}
          ><a class={`link text-sm ${dayjs(item.date_begins).isToday() ? 'link-primary' : ''}`} href="{route('event.practice.home', item.hash)}">{item.hash}</a></td>
          <td class="text-left">{item.name}</td>
          
          <td class="">{item.data.playerCount}</td>
          <td class="text-right"><span class="text-xs" title="{item.updated_at}">{item.diff_updated}</span></td>
          <!-- <td class="text-right"><span class="text-xs" title="{item.diff_created}">{dayjs(item.created_at).format('DD.MM.YYYY')}</span></td> -->

        </tr>
        {/if}
        {:else}
        <tr>
          <td colspan="5" class="text-center text-info py-4">No practices created</td>
        </tr>
        {/each}
      </tbody>
    </table>
    <p class="ml-2 mt-2 text-info text-sm">* Practices older than 2 days are automatically removed</p>


    <h3 class="text-primary font-bold mt-8" mb-2>Instant events</h3>
    <table class="table table-xs text-center">
      <thead>
        <tr class="text-center bg-base-200">
          <th>#</th>
          <th>Hash</th>
          <th class="text-left">Title</th>
          <th>Dates</th>
          <th>Players</th>
          <!-- <th>Sessions</th> -->
          <th>Courts</th>
          <th class="text-right">Updated</th>
          <th class="text-right">Created</th>
        </tr>
      </thead>
      <tbody>
        {#each eventsList as item,i}
        {#if item.type == 'Q'}
        <tr class={`${dayjs(item.date_begins).isToday() ? 'font-bold' : ''}`}>
          <th>{i+1}</th>
          <td
            class={`text-center}`}
          ><a class={`link text-sm ${dayjs(item.date_begins).isToday() ? 'link-primary' : ''}`} href="{route('event.quick.home', item.hash)}">{item.hash}</a></td>
          <td class="text-left">[{item.type}] {item.name}</td>
          <td>{#if dayjs(item.date_begins).isToday()}
              <span>today</span>
              {:else}
              <span>{dayjs(item.date_begins).format('DD.MM.YYYY')}</span>
            {/if}
          </td>
          <td class="">{item.data.playerCount}</td>
          <td class="">{item.data.courts}</td>
          <td class="text-right"><span class="text-xs" title="{item.updated_at}">{item.diff_updated}</span></td>
          <td class="text-right"><span class="text-xs" title="{item.diff_created}">{dayjs(item.created_at).format('DD.MM.YYYY')}</span></td>

        </tr>
        {/if}
        {:else}
        <tr>
          <td colspan="8" class="text-center text-info py-4">No instant events created</td>
        </tr>
        {/each}
      </tbody>
    </table>
  </div>

</div>