<script>
	import { inertia, Link, page, router } from '@inertiajs/svelte';

	export let event = $page.props.event;
	export let session = $page.props.session;

	// console.log("Tab for Info loaded.")
	// console.log($page.props.event)
	// console.log($page.props.session)
</script>

<div role="tabpanel" {...$$restProps}>
	{#if event.description.length }
	<blockquote class="bg-gray-200 border-l-4 border-gray-500 pl-4 py-2 rounded-md italic">{@html event.description.split('\n').join('<br>')}</blockquote>
	{:else}
	<p class="badge badge-info">No description provided for this event</p>
	{/if}

	<div class="stats stats-vertical sm:stats-horizontal shadow-lg border my-4 md:my-8">
	  <div class="stat">
	    <div class="stat-figure text-secondary">
	      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" enable-background="new 0 0 100 100" class="inline-block h-8 w-8 stroke-current fill-current">
			<g>
				<path d="M96.117,57.583l-16.185-7.719c3.774-2.373,6.338-7.016,6.338-12.354c0-7.754-5.397-14.034-12.058-14.034
					c-2.289,0-4.403,0.781-6.223,2.066c1.086,2.756,1.701,5.796,1.701,8.999c0,4.782-1.411,9.317-3.825,13.066
					c0.833,0.932,1.771,1.735,2.809,2.36l-0.01,0.003l10.426,4.971c2.947,1.427,4.853,4.583,4.853,8.045v13.54h11.148
					c1.489,0,2.694-1.401,2.694-3.13V60.492C97.785,59.21,97.126,58.072,96.117,57.583z"/>
				<path d="M31.567,49.825c0.861-0.548,1.651-1.221,2.368-1.987c-2.514-3.781-3.972-8.401-3.972-13.297c0-3.271,0.646-6.37,1.777-9.17
					c-1.76-1.172-3.773-1.895-5.948-1.895c-6.666,0-12.062,6.28-12.062,14.034c0,5.425,2.651,10.12,6.524,12.457l-16.349,7.61
					c-1.02,0.479-1.691,1.629-1.691,2.915v12.903c0,1.729,1.209,3.13,2.694,3.13h10.805v-13.54c0-3.503,1.938-6.672,4.943-8.08
					L31.567,49.825z"/>
				<path d="M76.94,59.386l-14.077-6.715l-5.952-2.84c2.6-1.637,4.711-4.165,6.093-7.21c1.096-2.418,1.749-5.155,1.749-8.081
					c0-1.642-0.26-3.201-0.623-4.706c-1.771-7.293-7.466-12.665-14.302-12.665c-6.708,0-12.316,5.181-14.196,12.266
					c-0.434,1.625-0.733,3.315-0.733,5.105c0,3.109,0.727,6.008,1.961,8.529c1.427,2.925,3.542,5.337,6.116,6.888l-5.702,2.654
					l-14.534,6.768c-1.262,0.591-2.092,2.016-2.092,3.606v13.54v2.43c0,2.14,1.492,3.875,3.333,3.875h51.691
					c1.841,0,3.333-1.735,3.333-3.875v-2.43v-13.54C79.006,61.401,78.19,59.99,76.94,59.386z"/>
			</g>
			</svg>
	    </div>
	    <div class="stat-title">Players</div>
	    <div class="stat-value">{session.players.length ?? '--'}</div>
	    <div class="stat-desc">currently participating</div>
	  </div>

	  <div class="stat">
	    <div class="stat-figure text-secondary">
	      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" enable-background="new 0 0 100 100" class="inline-block h-8 w-8 stroke-current fill-current">
	        <g>
				<path d="M46.05,60.163H31.923c-0.836,0-1.513,0.677-1.513,1.513v21.934c0,0.836,0.677,1.513,1.513,1.513H46.05
					c0.836,0,1.512-0.677,1.512-1.513V61.675C47.562,60.839,46.885,60.163,46.05,60.163z"/>
				<path d="M68.077,14.878H53.95c-0.836,0-1.513,0.677-1.513,1.513v67.218c0,0.836,0.677,1.513,1.513,1.513h14.127
					c0.836,0,1.513-0.677,1.513-1.513V16.391C69.59,15.555,68.913,14.878,68.077,14.878z"/>
				<path d="M90.217,35.299H76.09c-0.836,0-1.513,0.677-1.513,1.513v46.797c0,0.836,0.677,1.513,1.513,1.513h14.126
					c0.836,0,1.513-0.677,1.513-1.513V36.812C91.729,35.977,91.052,35.299,90.217,35.299z"/>
				<path d="M23.91,35.299H9.783c-0.836,0-1.513,0.677-1.513,1.513v46.797c0,0.836,0.677,1.513,1.513,1.513H23.91
					c0.836,0,1.513-0.677,1.513-1.513V36.812C25.423,35.977,24.746,35.299,23.91,35.299z"/>
			</g>	
	      </svg>
	    </div>
	    <div class="stat-title">Match progress</div>
	    <div class="stat-value">{session.statistics.matchesPlayed} / {session.statistics.matchesScheduled}</div>
	    <div class="stat-desc">{Math.floor(session.statistics.matchesPlayed/session.statistics.matchesScheduled*100)} % completed</div>
	  </div>

	</div>

</div>