<script>

/* 
 * This component creates a form layout with responsive
 * header and form field blocks.
 * It relies being wrapped into FormWrapper component
 * 
 * The data processing logic and action button bindings 
 * must be correctly declared in parent component. 
 * This is purely layout helper component.
 */

import SectionTitle from './SectionTitle.svelte';

const doNothing = () => {};

</script>


<div class="md:grid md:grid-cols-3 md:gap-6">
    <SectionTitle>
            <svelte:fragment slot="title">
                <slot name="title" />
            </svelte:fragment>
        
            <svelte:fragment slot="description">
                <slot name="description" />
            </svelte:fragment>
    </SectionTitle>

    <div class="mt-5 md:mt-0 md:col-span-2">
        <form on:submit|preventDefault={doNothing}>
            <div
                class="px-4 py-5 bg-white dark:bg-gray-800 sm:p-6 shadow {($$slots.actions) ? 'sm:rounded-tl-lg sm:rounded-tr-lg' : 'sm:rounded-lg'}">
                <slot name="formFields" />
            </div>

            {#if ($$slots.actions)}
            <div class="flex items-center justify-end px-4 py-3 text-end sm:px-6 shadow sm:rounded-bl-lg sm:rounded-br-lg border border-t bg-base-100">
                <slot name="actions" ></slot>
            </div>
            {/if}
        </form>
    </div>
</div>

