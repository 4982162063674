<script>
	import { inertia, Link, page, router } from '@inertiajs/svelte';
  export let userName = $page.props.auth.user?.name;
    
  if(!userName) userName = "---";

</script>

<div class=" flex flex-row">
  <div class="dropdown dropdown-end">
    <div tabindex="0" role="button" class="btn btn-ghost avatar placeholder">
      <div class="bg-neutral hover:bg-info hover:text-info-content text-neutral-content w-10 rounded-full">
        <span>{userName[0]}</span>
        <!-- <img
          alt="Avatar picture"
          src="https://img.daisyui.com/images/stock/photo-1534528741775-53994a69daeb.webp" /> -->
      </div>
      <span class="hidden lg:inline-block content-center pr-4">{userName}</span>
    </div>
    <ul
      tabindex="0"
      class="menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow">
      <li>
        <a href="{route('profile.show')}" class="hover:link-primary justify-between">
          Profile
          <span class="badge">New</span>
        </a>
      </li>
      <li class="cursor-not-allowed"><Link class="link-ghost" href="{route('profile.show')}">Teams<span class="badge badge-outline badge-secondary">Soon</span></Link></li>
      <li><a class="hover:link-error" use:inertia={{ href: route('logout'), method: 'post' }}>Logout</a></li>
    </ul>
  </div>
</div>