<script>
	
</script>

<div class="card bg-base-100 shadow-xl border border-primary">
	<div class="card-body">
		{#if ($$slots.cardTitle)}
	  	<h2 class="card-title text-primary"><slot name="cardTitle" /></h2>
	  	{/if}
	  	<div>
	  		<slot name="cardContent" />
	  	</div>
	  	{#if ($$slots.cardActions)}
		<div class="card-actions justify-end">
	  		<slot name="cardActions" />
		</div>
		{/if}
	</div>
</div> 