<script>
	import { inertia, Link, page, router } from '@inertiajs/svelte';

	export let standings = $page.props.standings;

	// console.log("Tab for Standings loaded.")
	// console.log($page.props.standings)

	$: if(standings) {
		standings = standings;
	}
</script>

<div role="tabpanel" {...$$restProps}>
	<table class="table table-xs sm:table-sm md:table-md lg:table-lg max-w-3xl">
	<thead>
	  <tr>
	    <th class="max-w-12">Rank</th>
	    <th></th>
	    <th>Player name</th>
	    <th>Games <span class="hidden md:inline">played</span></th>
	    <th>Pts/game</th>
	    <th>Bonus</th>
	    <th>Total <span class="hidden md:inline">points</span></th>
	  </tr>
	</thead>
	<tbody>
	  {#each standings as row, i (row.lid)}
	  <tr class={`hover ${(row.inactive) ? 'text-neutral' : ''} `}>
	    <td>
	      <span class="font-bold">{i+1}</span> 
	    </td>
	    <td>
	      {#if row.inactive }
	      <span class="cursor-help" title="Player is paused and not counted towards final standings">⏸️</span>
	      {/if}      
	    </td>
	    
	    <td class="text-nowrap">{row.name}</td>
	    <td class="text-center">{row.games}</td>
	    <td>{row.average.toFixed(2)}</td>
	    <td>{row.wins}</td>
	    <td class="font-bold text-center">{row.total}</td>
	  </tr>
	  {:else}
	  <tr>
	  	<td colspan="7" class="text-center text-info py-4">Standings are not available yet</td>
	  </tr>
	  {/each}
	</tbody>
</table>
</div>