<script>

	export let id = undefined;
	export let closeText = "Close";

</script>

<dialog id="{id}" class="modal">
  <div class="modal-box">
    <h3 class="text-lg font-bold">
    	<slot name="title" />
	</h3>

	<slot name="content">
		<p class="py-4">This dialog was not supplied with any information.</p>
		<p>Press [ESC] key or click the button below to close</p>	
	</slot>
    
    <div class="modal-action">
     	<slot name="actions" />
		<form method="dialog">
			<!-- if there is a button in form, it will close the modal -->
			<button class="btn btn-outline btn-info">{closeText}</button>
		</form>
    </div>
  </div>
  <form method="dialog" class="modal-backdrop">
    <button>{closeText}</button>
  </form>
</dialog>