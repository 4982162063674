<script>

  // This needs review, since Clipboard component might be unneeded

  import Clipboard from 'svelte-clipboard';

  // Check if the default slot has content
  const hasDefaultSlot = !!$$slots.default; // This converts the function to a boolean value. It's true if the default slot has content, false otherwise.

  // Render the default slot content conditionally
  function renderDefaultSlot() {

    console.log("slotrender:")
    console.log($$slots.default?.content)
    if (hasDefaultSlot) {
      return $$slots.default?.textContent; // render content of default slot
    }
    return null; // Or any fallback content you want to display
  }

  export let text;

  function copyToClipboard(txt) {
    console.log("Copy clicked!");

    navigator.clipboard.writeText(txt)
    .then(() => {
        // Optionally show a success message or visual feedback
        console.log('Copied to clipboard:', txt);
      })
    .catch(err => {
        console.error('Failed to copy: ', err);
      });
  }

</script>

<div class="flex items-center justify-between w-full max-w-screen p-2 rounded-lg border border-neutral">
  <code class="text-base-content text-sm">
    <slot /> 
  </code>
  <Clipboard text={text} let:copy>  
    <button class="btn btn-sm btn-ghost" title="copy the public URL" on:click={() => copyToClipboard(text)}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path d="M0 6.75C0 5.784.784 5 1.75 5h1.5a.75.75 0 0 1 0 1.5h-1.5a.25.25 0 0 0-.25.25v7.5c0 .138.112.25.25.25h7.5a.25.25 0 0 0 .25-.25v-1.5a.75.75 0 0 1 1.5 0v1.5A1.75 1.75 0 0 1 9.25 16h-7.5A1.75 1.75 0 0 1 0 14.25Z"></path><path d="M5 1.75C5 .784 5.784 0 6.75 0h7.5C15.216 0 16 .784 16 1.75v7.5A1.75 1.75 0 0 1 14.25 11h-7.5A1.75 1.75 0 0 1 5 9.25Zm1.75-.25a.25.25 0 0 0-.25.25v7.5c0 .138.112.25.25.25h7.5a.25.25 0 0 0 .25-.25v-7.5a.25.25 0 0 0-.25-.25Z"></path></svg>
    </button>
  </Clipboard>
</div>