<script context="module">
  import SiteLayout from 'lyo/SiteLayout.svelte';
  //import NestedLayout from 'lyo/NestedLayout.svelte';

  export const layout = [SiteLayout];
</script>

<script>
  import { inertia, Link, page, router } from '@inertiajs/svelte';
  import { onMount } from 'svelte';

	export let pagename = `Event "${$page.props.hash}" matchlist`;
  export let appName = $page.props.appName;
  export let matchList = $page.props.matchList;
  export let hash = $page.props.hash;



  // ========================================================
  // let form = useForm({
  //   nameList: [],
  //   count: 0,
  // });


  
  // ========================================================
  // Reactive statements

  $: if($page.props) {
      //console.log(`App name loaded: ${$page.props.appName}`);

      
    }

  // FUTURE TODO: Extract score display code into separate component, since the rendering logic might become quite extensive with matching of statuses etc.

  // ======================================================

    onMount(() => {
      // console.log("Loaded list:");
      console.log($page.props.matchList[0]);
      
    });


</script>


<svelte:head>
    <title>{pagename} > { appName }.</title>
</svelte:head>



  <main>
      <h1 class="m-6 text-3xl font-bold text-center">Match List for {hash}</h1>

      <div class="flex m-4 px-6 py-4 bg-base-100 rounded-lg border-t-4 border border-info max-w-lg mx-auto">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="flex flex-col mr-2 size-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
          </svg>

          <div class="flex flex-col">
              <h3 class="font-bold">Match list notes</h3>
              <span>Matches are displayed in order of scheduling. Color indicates matches in same time-slot (round)</span>
          </div>
      </div>

      <div class="border">

        <table class="table table-xs md:table-sm table-hover text-center max-w-lg mx-auto mb-12">
          <thead>
            <tr class="bg-base-200">
              <th>#</th>
              <th>Team A</th>
              <th>Score</th>
              <th>Team B</th>
              <th title="Court numbers are indicative, ">Info</th>
            </tr>
          </thead>
          <tbody>
          {#each matchList as row, i}
            <tr class={`hover ${(row.round %2 == 0) ? '' : 'bg-secondary/15'}`}>
              <td class="whitespace-nowrap" title="Number of match in the Tournament" >#{row.matchNumber} <span class="text-neutral-content/40" title="Timeslot number">[ {row.round} ]</span></td>
              <td class={`${row['result.winner'] == 1 ? 'font-bold' : ''}`}>{row.teamA}</td>
              <td class="">
                {#if (row.status != 'finished' || row.status == 'created' )}
                  <Link class="btn btn-outline btn-secondary" href="{route('event.matchscoreform', [hash, row.lid])}">Set score</Link>
                {:else}
                  {#if row.score }
                  <span class="text-base">{row.score[0]} : {row.score[1]}</span>
                  {:else}
                  <span class="text-neutral">{row.score ? row.score : '--:--' }</span>

                  {/if}
                {/if}
              </td>
              <td class={`${row['result.winner'] == 2 ? 'font-bold' : ''}`}>{row.teamB}</td>
              <td class="text-neutral">
                {#if row.status == "onCourt"}
                  <span class="badge badge-info text-white badge-xs" title="Timeslot number">Plays on {row['court.name']}</span>
                {:else}
                  {row['court.status'] ? row['court.status'] : '' }
                {/if}
              </td>
            </tr>
          {/each}
          </tbody>
        </table>


      </div>
    <footer>
      <p class="text-xs px-8 text-neutral text-right">MatchList[1] loaded successfully.</p>
    </footer>
  </main>