<script>
	
</script>


<header class="bg-white dark:bg-gray-800 shadow">
	<div class="mx-auto max-w-6xl px-4 py-2 md:py-4 lg:py-6 sm:px-6 lg:px-8">
	  <h1 class="text-xl md:text-2xl lg:text-3xl font-semibold text-primary">
	  	<slot />
	  </h1>
	</div>
</header>
