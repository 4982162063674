<script context="module">
  import SiteLayout from 'lyo/SiteLayout.svelte';
  //import NestedLayout from 'lyo/NestedLayout.svelte';

  export const layout = [SiteLayout];
</script>

<script>
  import { inertia, Link, page, router, useForm } from '@inertiajs/svelte';
  import { onMount } from 'svelte';

  // import ViewHeader from 'cmpt/app_ui/ViewHeader.svelte';
  

  
	export let pagename = 'Event "' + $page.props.hash + '"';
  export let appName = $page.props.appName;



  // ========================================================
  let form = useForm({
    nameList: [],
    count: 0,
  });


  
  // ========================================================
  // Reactive statements

  $: if($page.props) {
      // console.log(`App name loaded: ${$page.props.appName}`);
      // console.log(players)
    }


    // ======================================================
</script>


<svelte:head>
    <title>{pagename} > { appName }.</title>
</svelte:head>



  <main>
  	<div class="hero min-h-screen" style="">
      
      <div class="hero-content text-center">
        <div class="max-w-md">
          <h1 class="mb-5 text-5xl font-bold">{pagename}</h1>


          


        </div>
      </div>
    </div>
    <footer>
      <p class="text-xs px-8">Home page of event loaded successfully.</p>
    </footer>
  </main>