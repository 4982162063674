<script>


// Flash message object example that gets passed from backend (notice the double array brackets):

// Inertia::share('messages', [[
// 	"withIcon" => true, // should show icon (style dependent)
// 	"withCloseButton" => true, // false will auto-close the message after timeout
// 	"message" => "You need to know this!",
// 	"style" => "info" // current | info | warning | success | error
// ]]);


import { fade } from 'svelte/transition';
import { onMount, onDestroy } from 'svelte';
import { createEventDispatcher } from 'svelte';
    

export let messageObj;

export let withIcon = messageObj.withIcon ? messageObj.withIcon : false;
export let content = messageObj.message ? messageObj.message : "< Empty message >";
export let withClose = messageObj.withCloseButton ? messageObj.withCloseButton : false;
export let style = messageObj.style ? messageObj.style : "normal";
let timeOut = 3000;

const definedStyles = ["current", "info","warning","success", "error"];

const paths = {
	normal: "M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z",
	info: 	"M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z",
	warning:"M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z",
	success:"M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z",
	error:	"M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
}

// Renames style to default value -> "current" (because of DaisyUI class naming)
if(!definedStyles.includes(style)) {
	style = "current"
}


// ======================================================
const dispatch = createEventDispatcher();

function handleClose() {
	dispatch('closeAlert', { messageObj });
}


// ======================================================
let timeoutId;

onMount(() => {
	if(withClose == false) {
		timeoutId = setTimeout(handleClose, timeOut); // 5 seconds timeout
	}
});

// Clear the timeout when the component is destroyed
onDestroy(() => {
	if (timeoutId) {
	  clearTimeout(timeoutId);
	}
});

</script>


<div role="alert" class="min-w-52 flex justify-between alert shadow-md {`alert-${style} text-${style}-content {isSolid == false ? 'alert-outline' : ''} `}" transition:fade={{ duration: 400 }}>
	{#if withIcon == true && paths[style]}
	<svg
	xmlns="http://www.w3.org/2000/svg"
	fill="none"
	viewBox="0 0 24 24"
	class="h-6 w-6 shrink-0 stroke-current">
	<path
	  stroke-linecap="round"
	  stroke-linejoin="round"
	  stroke-width="2"
	  d="{paths[style]}"></path>
	</svg>
	{/if}
  <span>{content}</span>
	{#if withClose == true}
	<div>
    <button class={`btn btn-sm btn-circle btn-outline btn-${style}`} on:click={handleClose}>
    	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M9.036 7.976a.75.75 0 0 0-1.06 1.06L10.939 12l-2.963 2.963a.75.75 0 1 0 1.06 1.06L12 13.06l2.963 2.964a.75.75 0 0 0 1.061-1.06L13.061 12l2.963-2.964a.75.75 0 1 0-1.06-1.06L12 10.939 9.036 7.976Z"></path><path d="M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1ZM2.5 12a9.5 9.5 0 0 0 9.5 9.5 9.5 9.5 0 0 0 9.5-9.5A9.5 9.5 0 0 0 12 2.5 9.5 9.5 0 0 0 2.5 12Z"></path></svg>
    </button>
	</div>
	{/if}
</div>

<!-- The class names below are for Tailwind CSS purger, so they are included in final bundle -->
<!-- alert-info alert-success alert-warning alert-error -->