<script context="module">
  import SiteLayout from 'lyo/SiteLayout.svelte';
  //import NestedLayout from 'lyo/NestedLayout.svelte';

  export const layout = [SiteLayout];
</script>

<script>
  import { inertia, Link, page, router } from '@inertiajs/svelte';
  import { onMount } from 'svelte';


  import ViewHeader from 'cmpt/app_ui/ViewHeader.svelte';
  import EventInfoTab from './Partials/EventInfoTab.svelte';
  import EventMatchesTab from './Partials/EventMatchesTab.svelte';
  import EventPlayersTab from './Partials/EventPlayersTab.svelte';
  import EventStandingsTab from './Partials/EventStandingsTab.svelte';


  // =======================================================

  export let event = $page.props.eventData;
  export let session = $page.props.sessionData;
  export let matchList = $page.props.matchList;

  export let hash = event.hash;

	export let pageName = `${event.title}`;
  export let appName = $page.props.appName;


  // ========================================================
  // let form = useForm({
  //   nameList: [],
  //   count: 0,
  // });


  
  // ========================================================
  // Reactive statements

  $: if($page.props) {
      //console.log(`App name loaded: ${$page.props.appName}`);

      
    }

  // FUTURE TODO: Extract score display code into separate component, since the rendering logic might become quite extensive with matching of statuses etc.

  // ======================================================

    onMount(() => {
      // console.log("Loaded Event home page:");
      // console.log($page.props);
      
    });


</script>
<svelte:head>
    <title>{pageName} > { appName }.</title>
</svelte:head>


<div>
  <ViewHeader>
    {pageName}
  </ViewHeader>


  <div role="tablist" class="tabs tabs-bordered lg:tabs-lg my-4 sm:mx-2 md:mx-6 lg:mx-12 w-full md:max-w-5xl">
  <!-- Tab 1 -->
    <input type="radio" name="event_menu" role="tab" class="tab md:px-6 lg:px-12" aria-label="Info" checked="checked" />
    <EventInfoTab class="tab-content p-2 md:p-6 lg:p-8 max-w-2xl" />

  <!-- Tab 2 -->
    <input type="radio" name="event_menu" role="tab" class="tab md:px-6 lg:px-12" aria-label="Players" />
    <EventPlayersTab class="tab-content p-2 md:p-6 lg:p-8" />

  <!-- Tab 3 -->
    <input type="radio" name="event_menu" role="tab" class="tab md:px-6 lg:px-12" aria-label="Matches" />
    <EventMatchesTab  class="tab-content p-2 md:p-6 lg:p-8" />


  <!-- Tab 3 -->
    <input type="radio" name="event_menu" role="tab" class="tab md:px-6 lg:px-12" aria-label="Standings" />
    <EventStandingsTab class="tab-content p-2 md:p-6 lg:p-8" />

  </div>
</div>