<script>
	import { inertia, Link, page, router, useForm } from '@inertiajs/svelte';
    import { onMount } from 'svelte';

	import ActionSection from 'cmpt/app_ui/ActionSection.svelte';

const form = useForm({
    password: '',
});

export let confirmingUserDeletion = false;
export let passwordInput = null;

const confirmUserDeletion = () => {
    confirmingUserDeletion = true;

    setTimeout(() => passwordInput.value.focus(), 250);
};

const closeModal = () => {
    confirmingUserDeletion = false;
    $form.reset();
};


</script>

<ActionSection>
	<span slot="title">
            Delete Account
    </span>
    <span slot="description">
            Permanently delete your account.
    </span>

    <svelte:fragment slot="content">
		<div class="max-w-xl text-sm text-gray-600 dark:text-gray-400">
            Once your account is deleted, all of its resources and data will be permanently deleted. Before deleting your account, please download any data or information that you wish to retain.
        </div>
        <div class="mt-5">
                <button class="btn btn-error text-white" on:click|preventDefault={confirmUserDeletion}>
                    Delete Account
                </button>
            </div>
	</svelte:fragment>
    
	
</ActionSection>
