<script>
  
  import { inertia, Link, page, router } from '@inertiajs/svelte';
  import { onMount } from 'svelte';


  import Navbar from 'cmpt/app_ui/AppNavbar.svelte';
  import MessageContainer from 'cmpt/app_ui/MessageContainer.svelte';
    
  export let hasMessages = $page.props.messages?.length ? true : false;

  export let messages = [];
  export let appVer = $page.props.appName;


  // ========================================================



  // ========================================================
  // Reactive statements

  $: if($page.props.messages?.length) {
     //console.log(`Flash messages found: ${$page.props.messages.length}`)
     //console.log($page.props.messages)
     hasMessages = true;

  } else {
     // console.log("Nothing flashed")
     hasMessages = false;
     // console.log($page.props.messages)
  }



  // ========================================================
  onMount(() => {

  	// console.log(`route params:`);
  	// console.log();
    
    
    });


</script>


<div id="wrapper" class="bg-fixed bg-gradient-to-b to-stone-200 from-base-100 h-full">

  <Navbar />
  {#if hasMessages}
    <MessageContainer messages={$page.props.messages} />
	{/if}
	<slot />

</div>
