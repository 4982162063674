<script context="module">
  import AdminLayout from 'lyo/AdminLayout.svelte';
  //import NestedLayout from 'lyo/NestedLayout.svelte';

  export const layout = [AdminLayout];
</script>

<script>
  import { inertia, Link, page } from '@inertiajs/svelte'
  import { onMount } from 'svelte';


	export let pageName = 'Inspector: session';
  export let playerList, pairingList, matchList, configList, statisticsList;
  export let hash = $page.props.hash;
  export let eventHash = $page.props.eventHash;
  export let eventTitle = $page.props.eventTitle;

  export let showPairs = false;


  // console.log();
  //console.log($page.props.matchList[0]);
  function togglePairs() {
    showPairs = !showPairs;
  }

  onMount(() => {
    console.log($page.props);
  });

</script>








<svelte:head>
    <title>{pageName}</title>
</svelte:head>



  <main>
    <h2 class="text-2xl text-primary my-4 ml-8">Inspect session
    {#if $page.props.message}
      <span class="text-white badge badge-warning">{$page.props.message}</span>
    {:else}
    <span class=" badge badge-outline badge-primary">ID: "<b>{hash}</b>"</span>
    {/if}
    <Link href={route('debug.quickevents.listall', hash)}><span class="badge badge-outline badge-info">Part of: "{eventTitle}" ({eventHash})</span></Link>
    </h2>

    <div class="flex w-full flex-col lg:flex-row">
      <div class="card">
       
        <h3 class="mt-6 mb-2 py-2 px-4 bg-base-200"><a id="#players">List of players</a>
          <Link class="link link-accent text-sm underline" href="{route('debug.players.list.editform', hash)}">(Edit list)</Link>
        </h3>
      	<table class="table table-xs table-hover text-center">
            <thead>
              <tr>
                <th>#</th>
                <th>LID</th>
                <th></th>
                <th>Name</th>
                <th>Skill &pm;</th>
                <th title="played / scheduled">matches</th>
                <th>onCourt</th>
                <th>onBench</th>
                <th title="Player is Paused">⏸️</th>
                <th title="Player starts late">⏱️</th>
                <th title="Player needs depart early">✈️</th>
              </tr>
            </thead>
            <tbody>
              {#each playerList as row}
              <tr class:text-neutral={row.isPaused || row.startsLate } class="hover">
                <td class="font-bold text-neutral">{row.order}</td>
                <td><i>{row.lid}</i></td>
                <td>
                  {#if row.startsLate }
                  <span class="cursor-help" title="Player starts only from round #{row.startsLate}">⏱️</span>
                  {/if} 
                  {#if row.isPaused }
                  <span class="cursor-help" title="Player on pause">⏸️</span>
                  {/if}
                  {#if row.departsEarly }
                  <span class="cursor-help" title="Player needs to leave {row.departsEarly} rounds early">✈️</span>
                  {/if} 
                  
                </td>
                <td>{row.name}</td>
                <!-- <td>{row.uid ? "*****" : ""}</td> -->
                <td class:text-error={row.skillLevel < 0}>{row.skillLevel}</td>
                <td>{row.played} / {row.scheduled}</td>
                <td>{row.onCourt}</td>
                <td>{row.onBench}</td>
                <td class:text-warning={row.isPaused }>{row.isPaused ? "Yes" : ""}</td>
                <td class={row.startsLate > 0 ? "text-error font-bold" : ""}>{row.startsLate ? row.startsLate : ""}</td>
                <td>{row.departsEarly ? row.departsEarly : ""}</td>
              </tr>
              {:else}
              <tr>
                <td colspan="11"><h2 class="text-2xl text-neutral text-center"> List is empty</h2></td>
              </tr>
              {/each}
            </tbody>
          </table>


      </div>
      <div class="divider lg:divider-horizontal"></div>
      <div class="card">

        <h3 class="mt-6 mb-2 py-2 px-4 bg-base-200"><a id="#matches">Scheduled matches</a> <Link class="hidden link link-accent text-sm underline" href="{route('debug.session.matchesCounter', hash)}">Show matchCounter</Link></h3>
        <table class="table table-xs table-hover text-center max-w-2xl md:max-w-lg">
          <thead>
            <tr>
              <th>#</th>
              <th>Round</th>
              <th>Team A</th>
              <th>Score</th>
              <th>Team B</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
          {#each matchList as row, i}
            <tr class="hover {(row.round % 2 == 0) ? 'bg-primary/10':''}">
              <td>{row.matchNumber}</td>
              <td class="text-neutral">{row.round}</td>
              <td class="{row.winner == 1 ? 'font-bold' : ''}">
                <span class="text-nowrap">{row['teamA'][0]}</span> /
                <span class="text-nowrap">{row['teamA'][1]}</span>
              </td>
              <td class="">
                {#if row.status == "finished" }
                {row.score[0]}&nbsp;:&nbsp;{row.score[1]}
                {:else}
                  <span class="text-neutral">--:--</span>
                {/if}
              </td>
              <td class="{row.winner == 2 ? 'font-bold' : ''}">
                <span class="text-nowrap">{row['teamB'][0]}</span> /
                <span class="text-nowrap">{row['teamB'][1]}</span>
              </td>
              <td>{row.status}</td>
            </tr>
            {:else}
              <tr>
                <td colspan="5"><h2 class="text-2xl text-neutral text-center"> List is empty</h2></td>
              </tr>
          {/each}
          </tbody>
        </table>


      </div>
    </div>




    <h3 class="mt-6 mb-2 py-2 px-4 bg-base-200"><a id="#pairings">Pairings (teams)</a> <span class="inline-block link hover:link-primary float-right" on:click={togglePairs}>{showPairs ? 'Hide' : 'Show'} pairs</span></h3>

    {#if showPairs}
    <table class="table table-xs table-hover text-center mb-6 border-b border-b-neutral">
        <thead>
          <tr>
            <th>#</th>
            <th>LID</th>
            <th>Players</th>
            <!-- <th>Player 2</th> -->
            <th>Match #</th>
            <th>Played</th>
            <th>Opposing</th>
            <th>Strength</th>
            <th>Distance</th>
          </tr>
        </thead>
        
        <tbody>
          {#each pairingList as row, i}
          <tr class={`hover ${row.playedTogether == 0  ?  'text-neutral' : ''} ${row.playedTogether > 1 ? 'text-orange-800' : ''}}`}>
            <th>{i}</th>
            <td><i>{row.lid}</i></td>
            <td>{row.player_A} [{row.index_A}] ({row.lid_A}) / {row.player_B} [{row.index_B}] ({row.lid_B})</td>
            <!-- <td>{row.player_B} [{row.index_B}] ({row.lid_B})</td> -->
            <td>{#if row.playedTogether } |
                  {#each row.matchNumbers as item }
                    {item} |
                  {/each}
                {/if}
            </td>
            <td>{row.playedTogether}</td>
            <td>{row.playedAgainst}</td>
            <td>{row.teamStrength}</td>
            <td>{row.distance}</td>
          </tr>
          {:else}
              <tr>
                <td colspan="8"><h2 class="text-2xl text-neutral text-center"> List is empty</h2></td>
              </tr>
          {/each}
        </tbody>
      </table>
      {:else}
        <p class="py-4 text-center bg-base-200/25">Pairings list is hidden. <span class="link link-primary" on:click={togglePairs}>Show all</span></p>
      {/if}

      




    <div class="flex w-full flex-col lg:flex-row">
      <div class="card">
        
        <h3 class="mt-6 mb-2 py-2 px-4 bg-base-200"><a id="#Stats">Statistics</a></h3>
        <table class="table table-xs table-hover max-w-sm">
          <tbody>
          {#each Object.entries(statisticsList) as [item, value]}
            <tr class="hover">
              <th>{item}</th>
              <td>{value}</td>
            </tr>
          {/each}
          </tbody>
        </table>

      </div>
      <div class="divider lg:divider-horizontal"></div>
      <div class="card">
        
        <h3 class="mt-6 mb-2 py-2 px-4 bg-base-200"><a id="#Config">Config</a></h3>
        <table class="table table-xs table-hover max-w-sm">
          <tbody>
          {#each Object.entries(configList) as [item, value]}
            <tr class="hover">
              <th>{item}</th>
              <td>{value}</td>
            </tr>
          {/each}
          </tbody>
        </table>

      </div>
    </div>


  </main>