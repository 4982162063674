<script context="module">
  import SiteLayout from 'lyo/SiteLayout.svelte';
  //import NestedLayout from 'lyo/NestedLayout.svelte';

  export const layout = [SiteLayout];
</script>

<script>
    import { inertia, Link, page } from '@inertiajs/svelte';
    import { onMount } from 'svelte';

    
     export let pagename = 'Default page';
	   export let appName = $page.props.appName;
     export let themeName = false;

     // console.log(`Theme prop: ` + $props.uiTheme);
     console.log($page.props.hostAddress);

     onMount(() => {
        const themeAttribute = document.documentElement.getAttribute('data-theme');

        if (themeAttribute) {
          themeName = themeAttribute;
          console.log('Theme:', themeName);
          // Store the theme value in a variable or use it directly
          //localStorage.setItem('theme', theme); // Store in local storage for future use
        } else {
          console.log('No data-theme attribute found on the <html> element.');
        }
      });
</script>

<svelte:head>
    <title>{pagename} > { appName }</title>

    

</svelte:head>



  <main>
  	<div class="hero min-h-screen" style="background-image: url(/images/bg_hero3.jpg);">
      <div class="hero-overlay bg-opacity-70"></div>
      <div class="hero-content text-center text-neutral-content">
        <div class="max-w-md">
          <h1 class="mb-5 text-3xl font-bold">{pagename}</h1>
          <p class="mb-5 text-xl">This is placeholder for public home page</p>
          {#if themeName }
          <!-- <p class="mb-5">This instance uses "{themeName}" DaisyUI theme</p> -->
          {/if }
          <Link class="btn btn-accent " href="{route('login')}">Sign in</Link>
          <Link class="btn btn-primary btn-outline" href="{route('register')}">Register</Link>
        </div>
      </div>
    </div>

  </main>