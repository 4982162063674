<script>
	import { inertia, Link, page, router } from '@inertiajs/svelte';

  import { onMount } from 'svelte';


  export let standings;
	export let orgMode = false; // should use modal window call?
	export let sessionHash;
  export let display = "full";

  export let show = {
    wins: true,
    played: true,
    isPaused: true,
  };


  switch (display) {
    case "minimal":
      show.wins = false;
    break;
    case "paused":
      show.isPaused = false;
    break;
  }

  // ===============================
	$: if(standings) {
    console.log("standings changed in list component as well")
    console.log(standings)
  }

// =================================
  onMount(() => {
    console.log("standingsList loaded");
    console.log(standings)
    
  });

</script>


<table class="table table-xs sm:table-sm md:table-md lg:table-lg max-w-3xl text-center border-b border-base-200">
  <thead>
    <tr>
      <th class="max-w-12">Rank</th>
      <th></th>
      <th class="text-left">Player name</th>
      <th>Games <span class="hidden md:inline">played</span></th>
      <th>
        <span class="hidden md:inline">Points/game</span>
        <span class="md:hidden" title="Average points scored per game">Avg</span>
      </th>
      {#if show.wins}
      <th>
        <span class="hidden md:inline">TieBreaker</span>
        <span class="md:hidden" title="rounds Won. Used as tiebreaker.">W</span>
      </th>
      {/if}
      <th>Total <span class="hidden md:inline">points</span></th>
    </tr>
  </thead>
  <tbody>
    {#each standings as row, i (row.lid)}
    <tr class={`hover ${(row.inactive) ? 'text-neutral' : ''} `}>
      <td>
        <span class="font-bold">{i+1}</span> 
      </td>
      <td>
        {#if row.inactive }
        <span class="cursor-help" title="Player is paused and not counted towards final standings">⏸️</span>
        {/if}      
      </td>
      
      <td class="text-nowrap text-left">{row.name}</td>
      <td class="text-center">{row.games}</td>
      <td>{row.average.toFixed(2)}</td>
      {#if show.wins}
      <td>{row.wins}</td>
      {/if}
      <td class="font-bold text-center">{row.total}</td>
    </tr>
    {:else}
    <tr>
      <td colspan="7" class="text-center text-info py-4">Standings are not available yet</td>
    </tr>
    {/each}
  </tbody>
</table>
