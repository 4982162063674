<script>
	import { inertia, Link, page, router } from '@inertiajs/svelte';
    import { onMount } from 'svelte';


	import FlashMessage from 'cmpt/app_ui/FlashMessage.svelte';

    export let messages;  // passed in calling Layout
    export let hasMessages;  // passed in calling Layout


// =====================================================
    function handleCloseAlert(event) {
		const messageId = event.detail.messageObj;
		messages = messages.filter(message => message!== messageId);
	}

// =====================================================
    $: if(messages.length) {
    	hasMessages = true;
    	// console.log("MessageContainer recieved data")
    	// console.log(messages)
    } else {
      // console.log("nothing for MessageContainer")

    	setTimeout(()=>{ hasMessages = false;}, 300);
    }
	
</script>

<div class="top-16 toast toast-top toast-end {hasMessages ? '' : 'hidden'}">
  	{#each messages as box (box)}
  	<FlashMessage messageObj={box} on:closeAlert={handleCloseAlert}>{box.message}</FlashMessage>
  	{/each}
</div>
