<script>
	import { page, router, useForm } from '@inertiajs/svelte';
	import { onMount } from 'svelte';

	import FormSection from 'cmpt/app_ui/FormSection.svelte';
    import ActionMessage from 'cmpt/app_ui/ActionMessage.svelte';

	export let form = useForm({
		_method: 'PUT',
	    name: '',
	    email: '',
	    photo: null,
	});

	const updateProfileInformation = () => {
		console.log("form sent");
	    $form.post(route('user-profile-information.update'), {
	        errorBag: 'updateProfileInformation',
	        preserveScroll: true,
	        onSuccess: () => { console.log("update successful");},
	        onError: () => {
	        	console.log("errors in form")
	        	console.log($form.errors)
	        },
	    });
	};

	onMount( ()=>{

		$form.name = $page.props.auth.user.name;
		$form.email = $page.props.auth.user.email;
		console.log("UpdateProfileInformationForm loaded");
		// console.log($page.props)
	});
</script>


<FormSection>
	<span slot="title">
           Profile Information
    </span>
    <span slot="description">
            Update your account's profile information and email address.
    </span>

	<!-- Start form fields -->
    <svelte:fragment slot="formFields">
    	<label class="form-control w-full max-w-md">
          <div class="label">
            <span class="label-text">Your name</span>
          </div>
          <input type="text" placeholder="" class="input input-bordered w-full max-w-md" bind:value={$form.name} />
          <div class="label">
            {#if ($form.errors.name)}
            <span class="label-text-alt text-error">{$form.errors.name}</span>{/if}
          </div>
        </label>

        <label class="form-control w-full max-w-md">
	      <div class="label">
	        <span class="label-text">Your email</span>
	      </div>
	      <input type="text" placeholder="email address" class="input input-bordered w-full max-w-md" bind:value={$form.email} />
	      <div class="label">
	        {#if ($form.errors.email)}
	        <span class="label-text-alt text-error">{$form.errors.email}</span>{/if}
	      </div>
	    </label>


    </svelte:fragment>
    <!-- End of form fields -->

    <!-- Start buttons and their action bindings -->
    <svelte:fragment slot="actions">
		{#if $form.recentlySuccessful }
        <ActionMessage style="success">
                Profile updated
        </ActionMessage>
        {/if}
    	<button class="btn btn-primary text-white" on:click|preventDefault={updateProfileInformation} disabled={$form.processing}>Update Profile
        </button>
    </svelte:fragment>
    <!-- End action buttons -->

</FormSection>
