<script context="module">
  import AdminLayout from 'lyo/AdminLayout.svelte';
  //import NestedLayout from 'lyo/NestedLayout.svelte';

  export const layout = [AdminLayout];
</script>

<script>
    import { inertia, Link, page, useForm } from '@inertiajs/svelte';
    import { writable } from 'svelte/store';
    import { onMount } from 'svelte';

    
     export let pagename = 'Edit playerlist';
	   export let appName = $page.props.appName;
     export let message = $page.props.message;
     export let hash = $page.props.hash;
     export let showMessage = false;

     let form = useForm({list: false});
     export const list = writable($page.props.playerList);

     // Adds modifications to playerList table for UI needs
     list.update((items) => {
        return items.map((item) => ({
            ...item,
            startsLate: (item.startsLate ? item.startsLate : 0), //converts false to explicit zero
            departsEarly_on: ((item.departsEarly && item.scheduled == 0) ? true : false), //field used for actual switch. The dual condition ensures that on save the possible "1" value is not lost
            //isEditing: false
          })
        ); 
      });

     console.log("Recieved list:")
     console.log($page.props.playerList);

     console.log("Processed list:")
     console.log(list);



    // Handle form submission
     function saveChanges() {

        list.update((items) => {
          return items.map((item) => ({
              ...item,
              startsLate: (item.startsLate ? item.startsLate : false), //converts zero back to explicit false
              departsEarly: (item.departsEarly_on ? 2 : item.departsEarly), // if field was "switched on", set a value, or keep whatever was there originally
              isEditing: undefined,
              departsEarly_on: undefined
            })
          ); 
        });

        console.log("Processed list:")
        console.log($list);

        $form.list = $list;
        // console.log($form.list)
        $form.post(route('debug.players.list.update', hash));
     }

     onMount(() => {

        if($page.props.message) {
          showMessage = true;
        }

      });
</script>
<svelte:head>
    <title>{pagename} > { appName }</title>
</svelte:head>













<main class="m-4">
  	<h2 class="text-2xl text-primary my-4 ml-8">Edit players list for "{hash}" 
    {#if showMessage}
      <span class="text-white badge badge-warning">{message}</span>
    {:else}

    {/if}
    </h2>

    {#if showMessage }
    <div role="alert" class="alert hidden">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        class="stroke-info h-6 w-6 shrink-0">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
      </svg>
      <span>{message}</span>
    </div>
    {/if}




    <h3 class="mt-6 mb-2 py-2 px-4 bg-base-200"><a id="#players">Players {hash}</a></h3>
    <table class="table table-xs table-hover text-center">
        <thead>
          <tr>
            <th>ID</th>
            <!-- <th>LID</th> -->
            <th></th>
            <th>Name</th>
            <th>Skill &pm;</th>
            <th>Matches</th>
            <th title="onCourt / onBench">on/off</th>
            <!-- <th>onBench</th> -->
            <th>isPaused</th>
            <th title="Can only set this before first match is scheduled!" >startsLate</th>
            <th title="Can only set this before first match is scheduled!">departsEarly</th>
          </tr>
        </thead>
        <tbody>
          {#each $list as row (row.lid)}
          <tr class={`hover ${(row.isPaused || row.startsLate) ? 'text-neutral' : ''} `}>
            <td>
              <span class="font-bold">{row.order}</span> 
              <span class="text-neutral text-xs">({row.lid})</span>
            </td>
            <td>
              {#if row.startsLate }
              <span class="cursor-help" title="Player starts only from round #{row.startsLate}">⏱️</span>
              {/if} 
              {#if row.isPaused }
              <span class="cursor-help" title="Player on pause">⏸️</span>
              {/if}
              {#if row.departsEarly }
              <span class="cursor-help" title="Player needs to leave {row.departsEarly} rounds early">✈️</span>
              {/if} 
              
            </td>
            
            <td>
              <input type="text" placeholder="Unnamed player" class="input input-ghost w-full max-w-xs input-sm " bind:value={row.name}/>
            </td>
            
            <td class:text-error={row.skillLevel < 0}>
                <input type="range" min="-3" max="3" bind:value={row.skillLevel} class={`range range-xs max-w-20 ${row.skillLevel < 0 ? 'range-error': ''} ${row.skillLevel > 0 ? 'range-success':'range-info'}`} />
                <span>{row.skillLevel}</span>
            </td>


            <td>{row.played} / {row.scheduled}</td>
            <td>{row.onCourt} / {row.onBench}</td>
            <!-- <td></td> -->
            <td>
            <input type="checkbox" class="toggle toggle-warning" bind:checked={row.isPaused}  />
            </td>
            <td>
              {#if !row.scheduled }
                <input type="range" min="0" max="2" bind:value={row.startsLate} class={`range range-xs max-w-12 ${row.startsLate > 0 ? 'range-warning': 'range-info'}`} />
                <span class="text-black">{row.startsLate}</span>
              {:else}
                  <span class={`font-bold ${row.startsLate > 0 ? "text-black" : ""}`}>{row.startsLate ? row.startsLate : ""}</span>
              {/if}

            </td>
            <td>
              {#if !row.scheduled }
                <input type="checkbox" class="toggle toggle-warning" bind:checked={row.departsEarly}  />
              {:else}
                <span class={`font-bold ${row.departsEarly > 0 ? "text-error" : ""}`}>{row.departsEarly  ? "Active" : ""}</span>
              {/if}
            </td>
          </tr>
          {/each}
        </tbody>
      </table>

      <div class="mt-8">
        <button class="btn btn-primary" on:click={saveChanges} >Save changes</button>
      </div>




  </main>