<script>
	import { inertia, Link, page, router } from '@inertiajs/svelte';
  import { createEventDispatcher } from 'svelte';

  export let matchList;
	export let orgMode = false; // should use modal window call?
	export let scoreable = false; // can scores be edited from here
	export let sessionHash;
  export let display = "full";

  export let show = {
    roundNumber: true,
    matchNumber: true,
    court: true,
    status: true,
  };


  switch (display) {
    case "minimal":
       show.court = show.status = false;
    break;
  }

  // --------------------------------
  const dispatch = createEventDispatcher();

  function callModal(id) {
    //console.log(`CallForm: ${id}`);
    dispatch('openScoreForm', { match: id });
  }
	
	// console.log(matchList)
	// console.log($page.props.session)
</script>


<table class="table table-xs sm:table-sm text-center max-w-5xl">
  <thead>
    <tr>
      {#if show.roundNumber}<th class="table-cell" title="Round / timeslot number">Round</th>{/if}
      {#if show.matchNumber}<th>#</th>{/if}
      <th>Team A</th>
      <th>Score</th>
      <th>Team B</th>
      {#if show.court}<th title="Courts are simply numbered">Court</th>{/if}
      {#if scoreable}<th> </th>{/if}
      {#if show.status}<th class="hidden md:table-cell">Status</th>{/if}
    </tr>
  </thead>
  <tbody>
      {#each matchList as row, i}
        <tr class="hover {(row.round % 2 == 0) ? 'bg-info/10':''} ">
          {#if show.roundNumber}<td class="hidden md:table-cell {row.status == 'finished' ? 'text-neutral': ''}">{row.round+1}</td>{/if}
          {#if show.matchNumber}<td class="{row.status != "finished" ? 'text-neutral' :'text-info'}">{row.matchNumber}</td>{/if}
          <td class="{row.result.winner == 1 ? 'font-bold' : ''}">
            <span class="text-nowrap">{row['teamA'][0]}</span> /
            <span class="text-nowrap">{row['teamA'][1]}</span>
          </td>
          <td class="">
            {#if row.status == "finished" }
            {row.score[0]}&nbsp;:&nbsp;{row.score[1]}
            {:else}
               <span class="text-neutral">--:--</span>
            {/if}
          </td>
          <td  class="{row.result.winner == 2 ? 'font-bold' : ''}">
            <span class="text-nowrap">{row['teamB'][0]}</span> /
            <span class="text-nowrap">{row['teamB'][1]}</span>
          </td>
          {#if show.court}<td class="{row.status == 'finished' ? 'text-neutral': ''}">{row.court+1}</td>{/if}
          <td>
            {#if scoreable && !orgMode }
              <Link href={route('pub.match.scoreform',{sessionHash:sessionHash, matchId: row.lid})} class="btn btn-outline btn-secondary btn-xs">Score</Link>  
            {/if}
            {#if scoreable && orgMode }
              <button on:click={callModal(row.lid)} class="btn btn-outline btn-secondary btn-xs">score</button>  
            {/if}
          </td>
          {#if show.status}<td class="hidden md:table-cell {row.status == 'finished' ? 'text-neutral' : ''}">{row.status}</td>{/if}
        </tr>
        {:else}
          <tr>
            <td colspan="5"><h2 class="text-2xl text-neutral text-center"> List is empty</h2></td>
          </tr>
      {/each}
      </tbody>
</table>
