<script>
	import { router, useForm } from '@inertiajs/svelte';
	import { onMount } from 'svelte';

	import FormSection from 'cmpt/app_ui/FormSection.svelte';
    import ActionMessage from 'cmpt/app_ui/ActionMessage.svelte';

	export let form = useForm({
	    current_password: '',
	    password: '',
	    password_confirmation: '',
	});
	
	export let passwordInput = null;
	export let currentPasswordInput = null;

	const updatePassword = () => {
		console.log("form sent");
	    $form.put(route('user-password.update'), {
	        errorBag: 'updatePassword',
	        preserveScroll: true,
	        onSuccess: () => {$form.reset(); console.log("update successful");},
	        onError: () => {
	        	console.log("errors in form")
	        	console.log($form.errors)

	            if ($form.errors.password) {
	                $form.reset('password', 'password_confirmation');
	                passwordInput.focus();
	            }

	            if ($form.errors.current_password) {
	                $form.reset('current_password');
	                currentPasswordInput.focus();
	            }
	        },
	    });
	};

	onMount( ()=>{
		// console.log("UpdatePassForm loaded");
	});
</script>


<FormSection>
	<span slot="title">
           Update password
    </span>
    <span slot="description">
            Ensure your account is using a long, random password to stay secure.
    </span>

	<!-- Start form fields -->
    <svelte:fragment slot="formFields">
    	<label class="form-control w-full max-w-md md:mb-4">
          <div class="label">
            <span class="label-text">Current password</span>
          </div>
          <input type="password" placeholder="" class="input input-bordered w-full max-w-md" bind:value={$form.current_password} bind:this={currentPasswordInput} />
          
          {#if ($form.errors.current_password)}
          <div class="label">
            <span class="label-text-alt text-error">{$form.errors.current_password}</span>
          </div>
          {/if}
        </label>

        <label class="form-control w-full max-w-md md:mb-4">
          <div class="label">
            <span class="label-text">New password</span>
          </div>
          <input type="password" placeholder="" class="input input-bordered w-full max-w-md" bind:value={$form.password} bind:this={passwordInput} />
          
          {#if ($form.errors.password)}
          <div class="label">
            <span class="label-text-alt text-error">{$form.errors.password}</span>
          </div>
          {/if}
        </label>

        <label class="form-control w-full max-w-md">
          <div class="label">
            <span class="label-text">Confirm new password</span>
          </div>
          <input type="password" placeholder="" class="input input-bordered w-full max-w-md" bind:value={$form.password_confirmation} >
          
          {#if ($form.errors.password_confirmation)}
          <div class="label">
            <span class="label-text-alt text-error">{$form.errors.password_confirmation}</span>
          </div>
          {/if}
        </label>
    </svelte:fragment>
    <!-- End of form fields -->

    <!-- Start buttons and their action bindings -->
    <svelte:fragment slot="actions">
		{#if $form.recentlySuccessful }
        <ActionMessage style="success">
                Password saved
        </ActionMessage>
        {/if}
    	<button class="btn btn-primary text-white" on:click|preventDefault={updatePassword} disabled={$form.processing}>Update Password
        </button>
    </svelte:fragment>
    <!-- End action buttons -->

</FormSection>
