
<script>
	import { inertia, Link } from '@inertiajs/svelte';
	import { page } from '@inertiajs/svelte';

	export let href;
	let currentUrl = route().current();
	
</script>


<li class="relative" class:active="{ href === currentUrl }">
    <Link class="inline-flex items-center w-full link link-hover link-primary" {href} >
                
		<svg class="w-5 h-5" aria-hidden="true" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
               
		<slot name="svgIconPath">
			<path d=""></path>
		</slot>

		</svg>

		<slot name="navLabel">NAVLINK</slot>
	</Link>
</li>

