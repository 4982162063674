<script>
  
  import { inertia, Link, page, router } from '@inertiajs/svelte';
  import { onMount } from 'svelte';
  

  let isBad = false;
  let name = "(UNREGISTERED)";
  export let appVer = $page.props.appName;

  import MessageContainer from 'cmpt/app_ui/MessageContainer.svelte';
    
  export let hasMessages = $page.props.messages?.length ? true : false;
  export let messages = [];

  function signOut() {
  	router.post(route('logout'));
  }

  function signIn() {
  	router.get(route('login'));
  }


  // ######################################
  $: if(false) {


  }

  // ######################################
  onMount(() => {
  	console.log("SiteLayout loaded.")

  	// console.log(`messages: `);
   //  console.log($page.props.messages);
  	// console.log(hasMessages);
    
  });


</script>


<section class="bg-base-100">

	<div class="navbar bg-primary text-primary-content shadow-lg">
	  <div class="flex-1">
	    <Link href={route('app.home')} class="btn btn-ghost normal-case text-sm md:text-base lg:text-lg"><img class="md:h-8 h-6" src="/img/logo_playminton_inv_tr.png" alt="Playminton"></Link>
	  </div>
	  <div class="flex-none">
	    <ul class="menu menu-horizontal px-1">
	      <li title="Documentation still in progress..."><a class="btn btn-ghost btn-sm normal-case text-sm md:text-base font-normal">Documentation</a></li>
	      {#if $page.props.auth.user}
	      <li><a on:click|preventDefault={signOut} class="btn btn-outline btn-outline btn-sm text-sm md:text-base font-normal">Sign out</a></li>
	      {:else}
	      <li><a on:click|preventDefault={signIn} class="btn btn-outline btn-outline btn-sm text-sm md:text-base font-normal">Sign in</a></li>


	      {/if}
	    </ul>
	  </div>
	</div>
  {#if hasMessages}
    <MessageContainer messages={$page.props.messages} />
  {/if}


	<slot />

</section>



<style>
	
	li.current {
		@apply font-bold underline;
	}
</style>