<script context="module">
  import SiteLayout from 'lyo/AppLayout.svelte';
  
  //import NestedLayout from 'lyo/NestedLayout.svelte';

  export const layout = [SiteLayout];

</script>

<script>
    import { inertia, Link, page, router } from '@inertiajs/svelte';
    import { onMount } from 'svelte';

    import ViewHeader from 'cmpt/app_ui/ViewHeader.svelte';
    import DialogModal from 'cmpt/app_ui/DialogModal.svelte';
    import ActionMessage from 'cmpt/app_ui/ActionMessage.svelte';
    import FutureFeature from 'cmpt/app_ui/FutureFeature.svelte';
    
     export let pagename = 'Organizer Central';
	   export let appName = $page.props.appName;
     export let myEvents = $page.props.myEvents;

     onMount(() => {
      console.log("Dashboard loaded:")
      console.log(myEvents);

      });

     const showTest = () => {
      router.get(route('app.test'))
      console.log("Dash button clicked")
     };

</script>

<svelte:head>
    <title>{pagename} > { appName }</title>
</svelte:head>



<div class="h-full">
  <ViewHeader>
    {pagename}
  </ViewHeader>

  <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mx-auto max-w-6xl min-h-full py-6">

      <div class="card bg-base-100 shadow-xl border border-secondary">
        <div class="card-body">
          <h2 class="card-title text-secondary">Practice</h2>
          <p class="text-neutral-content">Create an immediate single-court practice session.</p>
          <div class="card-actions justify-end">
            <Link href="{route('event.practice.new')}" class="btn btn-secondary">Set up practice</Link>
          </div>
        </div>
      </div>



      <div class="card bg-base-100 shadow-xl border border-primary">
        <div class="card-body">
          <h2 class="card-title text-primary">Instant event</h2>
          <p class="text-neutral-content">Create a "pop-up event" from a single group of players</p>
          <div class="card-actions justify-end">
            <Link href="{route('event.quick.new')}" class="btn btn-primary btn-outline">Quick setup</Link>
          </div>
        </div>
      </div>


      <div class="card bg-base-100 shadow-xl border border-primary">
        <div class="card-body">
          <h2 class="card-title text-primary">
            {#if myEvents.hasQuickEvents || myEvents.hasPratice }
            <Link class="link link-primary" href="{route('event.quick.list')}" title="list all events">My events</Link>
            {:else}
            My events
            {/if}
          </h2>
          <ul>
            {#if myEvents.hasQuickEvents }
            <li>Latest instant event: <Link class="link link-primary" href="{route('event.quick.home', myEvents.latest.hash)}">{myEvents.latest.title} </Link><span class="text-sm text-neutral">({myEvents.latest.date})</span></li>
            {:else}
            <li class="text-neutral">No Instant events found</li>
            {/if}

            {#if myEvents.hasPractice }
            <li>Latest practice: <Link class="link link-primary" href="{route('event.practice.home', myEvents.practice.hash)}">{myEvents.practice.title} </Link><!-- <span class="text-sm text-neutral">({myEvents.practice.date})</span> --></li>
            {:else}
            <li class="mt-4 text-neutral">No recent practices found</li>
            {/if}


            {#if myEvents.hasEvents }
            <li><Link class="link link-primary" href="{route('event.quick.home')}">List my tournaments</Link></li>
            {:else}
            <li class="text-neutral">No Tournaments created</li>
            {/if}
          </ul>
          <!-- <p class="text-neutral-content">Create a new event</p> -->

          <div class="card-actions justify-end hidden">
            <Link href="{route('event.create')}" class="btn btn-primary btn-outline">Create new</Link>
          </div>
        </div>
      </div>


      <div class="card bg-base-100 shadow-xl border border-primary">
        <div class="card-body">
          <h2 class="card-title text-neutral">New tournament<span class="badge badge-info badge-outline text">Soon</span></h2>
          <p class="text-neutral-content">Create a new event with multi-group, multi-court setups.</p>
          <p class="text-neutral-content">Intended for larger, promoted events.</p>
          <div class="card-actions justify-end">
            <Link href="{route('event.create')}" class="btn btn-primary btn-outline btn-disabled">Create new</Link>
          </div>
        </div>
      </div>


      <div class="card bg-base-100 shadow">
        <div class="card-body">
          <h2 class="card-title text-neutral">Mixed teams <span class="badge badge-neutral badge-outline text">Soon</span></h2>
          <FutureFeature stage="lock" withIcon>
            <!-- <span slot="title">Two-Factor authentication</span> -->
                <span slot="description">Eanbles to match players that only MX teams are formed and rotated.</span>
          </FutureFeature>
          <div class="card-actions justify-end">
            <span class="badge badge-info badge-outline">Premium</span>
            <!-- <button class="btn btn-outline btn-disabled">Soon</button> -->
          </div>
        </div>
      </div>


      <div class="card bg-base-100 shadow">
        <div class="card-body">
          <h2 class="card-title text-neutral">Online registrations <span class="badge badge-neutral badge-outline text">Soon</span></h2>
          <FutureFeature stage="plan" withIcon>
            <!-- <span slot="title">Two-Factor authentication</span> -->
                <span slot="description">Will allow players to sign up to events directly</span>
          </FutureFeature>
          <!-- <div class="card-actions justify-end">
            <button class="btn btn-outline btn-disabled">Soon</button>
          </div> -->
        </div>
      </div>  


      <div class="card bg-base-100 shadow">
        <div class="card-body">
          <h2 class="card-title text-neutral">School events<span class="badge badge-neutral badge-outline text">Soon</span></h2>
          <FutureFeature stage="plan" withIcon>
            <!-- <span slot="title">Two-Factor authentication</span> -->
                <span slot="description">Organize school events with specific player groups and team score calculations</span>
          </FutureFeature>
          <div class="card-actions justify-end">
            <!-- <button class="btn btn-outline btn-disabled">OK</button> -->
            <span class="badge badge-info badge-outline">School managers only</span>
          </div>
        </div>
      </div>           


  </div> <!-- End grid -->


</div>