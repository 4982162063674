<script>
	import { inertia, Link, page, router } from '@inertiajs/svelte';

	export let event = $page.props.event;
	export let session = $page.props.session;
	export let players = session.players
	// console.log(players)
	// console.log($page.props.session)
</script>


<div role="tabpanel" {...$$restProps}>
<table class="table table-xs sm:table-sm md:table-md max-w-xl">
	<thead>
	  <tr>
	    <th class="max-w-12">#</th>
	    <th></th>
	    <th>Players name</th>
	    <th>Progress</th>
	  </tr>
	</thead>
	<tbody>
	  {#each players as row, i (row.lid)}
	  <tr class={` ${(row.isPaused || row.startsLate) ? 'text-neutral' : ''} `}>
	    <td>
	      <span class="font-bold">{i+1}</span> 
	    </td>
	    <td>
	      {#if row.isPaused }
	      <span class="cursor-help" title="Player is paused and likely will not play">⏸️</span>
	      {/if}      
	    </td>
	    
	    <td>
	      {row.name}
	    </td>

	    <td>{row.played} / {row.scheduled}</td>
	  </tr>
	  {/each}
	</tbody>
</table>
</div>