<script context="module">
  import SiteLayout from 'lyo/SiteLayout.svelte';
  import NestedLayout from 'lyo/NestedLayout.svelte';

  export const layout = [SiteLayout, NestedLayout];
</script>

<script>
    import { inertia, Link } from '@inertiajs/svelte'
    
	export let greeting = 'Scripted text works fine.';
</script>

<svelte:head>
    <title>Dynamic nested page</title>

    

</svelte:head>



  <main>
  	<div class="mockup-phone border-primary">
	  <div class="camera"></div> 
	  <div class="display">
	    <div class="artboard artboard-demo phone-1">{ greeting }</div>
	  </div>
	</div>

  </main>