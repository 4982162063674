<script>
import { fade } from 'svelte/transition';

// export let show;
export let withIcon = false;
export let style = "current";

const definedStyles = ["current", "info","warning","success","error"];

const paths = {
	normal: "M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z",
	info: 	"M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z",
	warning:"M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z",
	success:"M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z",
	error:	"M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
}

// Renames style to default, which is "current" (because of DaisyUI class naming)
if(!definedStyles.includes(style)) {
	style = "current"
}

</script>

<div class="badge badge-{style} mx-4" in:fade={{ duration: 250 }} out:fade={{ duration: 250, delay: 2500 }}>
	<slot>Message text was not specified! :( </slot>
</div>
<!-- Alert component was replaced by badge, which is more suited for inline messages -->
<!-- <div role="alert" class="my-2 alert {`alert-${style} text-${style}-content`}" transition:fade>
	{#if withIcon == true && paths[style]}
	<svg
	xmlns="http://www.w3.org/2000/svg"
	fill="none"
	viewBox="0 0 24 24"
	class="h-6 w-6 shrink-0 stroke-current">
	<path
	  stroke-linecap="round"
	  stroke-linejoin="round"
	  stroke-width="2"
	  d="{paths[style]}"></path>
	</svg>
	{/if}
  <span><slot>Message text was not specified! :( </slot></span>
</div> -->

<!-- The class names below are for Tailwind CSS purger, so they are included in final bundle -->
<!-- badge-info badge-success badge-warning badge-error -->