<script context="module">
  import SiteLayout from 'lyo/AppLayout.svelte';

  export const layout = [SiteLayout];
</script>

<script>
  import { inertia, Link, page, router, useForm } from '@inertiajs/svelte';
  import { onMount, onDestroy } from 'svelte';

  import ViewHeader from 'cmpt/app_ui/ViewHeader.svelte';
  import Card from 'cmpt/app_ui/Card.svelte';
  import CardGrid from 'cmpt/app_ui/CardGrid.svelte';
  import CodeBlock from 'cmpt/app_ui/CodeBlock.svelte';
  import ModalForm from 'cmpt/app_ui/ModalForm.svelte';
  import MatchList from 'cmpt/app_ui/MatchListTable.svelte';
  import StandingsList from 'cmpt/app_ui/StandingsListTable.svelte';

  // import ViewHeader from './Partials/QuickEventHeader.svelte';
  // import ManagePlayersForm from './Partials/ManagePlayersForm.svelte';

  import dayjs from 'dayjs';
  import relativeTime from 'dayjs/plugin/relativeTime';
  import customParseFormat from 'dayjs/plugin/customParseFormat';
  import isToday from 'dayjs/plugin/isToday'
  dayjs.extend(customParseFormat);
  dayjs.extend(relativeTime);
  dayjs.extend(isToday);


  const minPlayers = 5;

  export let eventData = $page.props.event;
  export let sessionData = $page.props.session;
  export let matchList = $page.props.matchList;
  export let standingsList = $page.props.standings;
  export let pageName = eventData.name ?? 'Practice';
  export let appName = $page.props.appName;
  export let public_url = route('pub.qe.home', eventData.hash);
	export let hash = eventData.hash;
  export let playerNames = getPlayers();
  export let enoughPlayers = playerNames.length >= minPlayers;
  export let areScheduled = sessionData.statistics.matchesScheduled || false;
  export let modalFrame;

  // ========================================================
  function showDate(myDate) {
    let show = '';

    if(dayjs(eventData.date_begins).isToday()) {
      show = "today";
    } else {
      show = dayjs(eventData.date_begins).format('DD.MM.YY');
    }
    return show;
  }


  // ----------------------------------
  function getPath(input) {
    const url = new URL(input);
    return url.pathname;
  }

  // ----------------------------------
  function addPlayerName() {
    console.log("Player name adding not implemented yet");
  }

  // ----------------------------------
  function resetSchedule() {
    //console.log("reset schedule called")
    router.post(route('event.schedule.reset', sessionData.hash), {replace: true});
    return true;
  }


  // ----------------------------------
  function openScoreModal(event) {
    //console.log('Event received from matchlist:', event.detail.match);
    let url = route('event.match.scoreform', { sessionHash: sessionData.hash, matchId: event.detail.match});

    modalFrame.src = url;
    scoreFormDialog.showModal();
    
    //console.log(url);

    return true;
  }

  // ----------------------------------
  function closeScoreModal(event) {
    scoreFormDialog.close();
    setTimeout(() => { 
      modalFrame.src = 'about:blank'
    }, 1000); // unloads Iframe content

    router.get(route('event.practice.home', eventData.hash), { 
      onStart: () => console.log("starting practice reload..."),
      onSuccess: () => console.log("reloaded"),
      only: ['matchList','standings'],
      preserveScroll: true,
      replace: true
    });
  }

  // ----------------------------------
  // simplifies recieved player names' list
  function getPlayers() {
    // console.log("getPlayers called:");
    
    return $page.props.playerList.map(row => {
      const name = row.name;
      const isPaused = (row.isPaused || row.startsLate > 0) ? true : false;
      return { name, isPaused};
    });
  }

  // ========================================================
  let form = useForm({
    nameList: [],
    count: 0,
  });


  
  // ========================================================
  // Reactive statements

  $: if(standingsList) {
      console.log(`standings changed in practice view`);
      // console.log(standingsList);
      // console.log(matchList[4]);
    }


  // ======================================================
  onMount(() => {
    //console.log($page.props);
    console.log("Practice loaded:")
    // console.log(eventData);
    // console.log(areScheduled);
    modalFrame = document.getElementById("scoreFrame");

    // For closeModal calls
    window.addEventListener('message', closeScoreModal);
    // console.log(sessionData.hash);

  });

  onDestroy(() => {
    window.removeEventListener('message', closeScoreModal);
  });
</script>
<svelte:head>
    <title>{pageName} > { appName }.</title>
</svelte:head>












<div class="h-full">
  <ViewHeader>
    {pageName}
  </ViewHeader>

  <div class="px-2 max-w-xl mx-auto pb-8">
    <h3 class="font-bold text-primary my-4">Matches</h3>
    <MatchList scoreable="yes" on:openScoreForm={openScoreModal} matchList={matchList} sessionHash={sessionData.hash} orgMode="yes" display="minimal" />
    
    <div class="my-4 items-center justify-end px-4 py-2 text-end">
      <button class="btn btn-primary btn-sm" use:inertia={{ href: route('event.matches.addround', sessionData.hash, 1 ), method: 'post' }}>Add round</button>
    </div>

    <h3 class="font-bold text-primary mt-4">Standings</h3>
    
    <StandingsList standings={standingsList} />
  </div>
    

  <dialog id="scoreFormDialog" class="modal">
    <div class="modal-box min-w-sm h-full">
      <form method="dialog">
        <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
      </form>
      <iframe id="scoreFrame" src="" class="w-full h-full"></iframe>
    </div>
  </dialog>
</div>