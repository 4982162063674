<script>
  import { inertia, Link, page, router } from '@inertiajs/svelte';
  import { onMount } from 'svelte';


   import HeaderUserNotifications from 'cmpt/app_ui/NavbarUserNotifications.svelte';
   import HeaderUserMenu from 'cmpt/app_ui/NavbarUserMenu.svelte';


router.on('exception', (event) => {
  console.log(`An unexpected error occurred during an Inertia visit.`)
  console.log(event.detail)
})

router.on('invalid', (event) => {
  console.log(`An invalid Inertia response was received.`)
  console.log(event.detail)
})

// router.on('before', (event) => {
//   console.log(`Before starting a visit to ${event.detail.visit.url}`)
//   console.log(event.detail)

// })

// router.on('start', (event) => {
//   console.log(`Starting a visit to ${event.detail.visit.url}`)
// })

// router.on('success', (event) => {
//   console.log(`Successfully made a visit to ${event.detail.page.url}`)
//   console.log(event.detail)
// })

router.on('error', (errors) => {
  console.log(errors)
})


  function goHome() {
    router.get(route('app.home'));
  }

  function signOut() {
    router.post(route('logout'));
  }

  function signIn() {
    router.get(route('login'));
  }
  
</script>

<div class="navbar bg-base-100 border-b border-b-neutral">
  <div class="navbar-start">
    <!-- Mobile screen size menu -->
    <div class="dropdown">
      <div tabindex="0" role="button" class="btn btn-ghost lg:hidden">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h8m-8 6h16" />
        </svg>
      </div>
      <ul
        tabindex="0"
        class="menu menu-sm dropdown-content bg-base-100 z-[1] left-[-1rem] w-screen mt-3 p-4 shadow-lg">
        <li><Link href="{route('app.home')}" class=" link-primary">Dashboard</Link></li>
        <li><Link href="{route('app.testpage')}" class=" link-primary">Test events</Link></li>
        <li><Link href="{route('inspector')}" class=" link-neutral">DEV: Inspector</Link></li>
        <li>
        <!-- <details> -->
        <a class="text-gray-500">Documentation <span class="badge badge-sm badge-outline badge-secondary">Soon</span></a>
          <ul class="p-2 hidden">
            <li><a rel="noopener noreferrer" aria-label="Opens in a new window">Submenu 1</a></li>
            <li><a rel="noopener noreferrer" aria-label="Opens in a new window">Submenu 2</a></li>
          </ul>
        <!-- </details> -->
      </li>
      </ul>
    </div>
    <!-- Mobile screen size menu ends -->

    <!-- Logo -->
    <Link href="{route('app.home')}" class="pl-4 md:pl-2"><img class="md:h-8 h-6" src="/img/logo_playminton_tr2.png" alt="Playminton"></Link>
    <!-- Logo ends -->
  </div>
  <div class="navbar-center hidden lg:flex">

    <!-- Large screen menu -->
    <ul class="menu menu-horizontal px-1">
      <li><Link href="{route('app.home')}" class=" link-primary">Dashboard</Link></li>
      <li><Link href="{route('test.current')}" class=" link-primary">Run "/test"</Link></li>
      <li><Link href="{route('inspector')}" class=" link-neutral">DEV: Inspector</Link></li>
      <li>
        <!-- <details> -->
        <a class="text-gray-500">Documentation <span class="badge badge-sm badge-outline badge-secondary">Soon</span></a>
          <ul class="p-2 hidden">
            <li><a rel="noopener noreferrer" aria-label="Opens in a new window">Submenu 1</a></li>
            <li><a rel="noopener noreferrer" aria-label="Opens in a new window">Submenu 2</a></li>
          </ul>
        <!-- </details> -->
      </li>
    </ul>
  </div>
  <div class="navbar-end">
    <!-- User profile and logout buttons -->
    <HeaderUserNotifications />
    <HeaderUserMenu />
    <!--  -->
  </div>
</div>
