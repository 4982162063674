<script>
	import { inertia, Link, page, router, useForm } from '@inertiajs/svelte';
    import { onMount } from 'svelte';

    import ActionSection from 'cmpt/app_ui/ActionSection.svelte';
    import ActionMessage from 'cmpt/app_ui/ActionMessage.svelte';
	import DialogModal from 'cmpt/app_ui/DialogModal.svelte';

const form = useForm({
    password: '',
});

export let confirmingLogout = false;
export let passwordInput = null;
export let sessions = $page.props.sessions;


const confirmLogout = () => {
    confirmSessionsLogout.showModal();
    confirmingLogout = true;

    setTimeout(() => passwordInput.focus(), 250);
};

const logoutOtherBrowserSessions = () => {
    $form.delete(route('other-browser-sessions.destroy'), {
        preserveScroll: true,
        onSuccess: () => confirmSessionsLogout.close(),
        onError: () => passwordInput.focus(),
        onFinish: () => router.reload(),
    });
};


const closeModal = () => {
    confirmingLogout = false;
    $form.reset();
};

onMount(() => {
    //$form.password = passwordInput.value;
    // console.log(passwordInput)
    // console.log(passwordInput.value)
});


</script>

<ActionSection>
	<span slot="title">
            Browser Sessions
    </span>
    <span slot="description">
            Manage and log out your active sessions on other browsers and devices.
    </span>

    <svelte:fragment slot="content">
		<div class="max-w-xl text-sm text-gray-600 dark:text-gray-400">
            If necessary, you may log out of all of your other browser sessions across all of your devices. Some of your recent sessions are listed below; however, this list may not be exhaustive. If you feel your account has been compromised, you should also update your password.
        </div>

        {#if (sessions.length > 0)}
        <div class="mt-5 space-y-6">
            {#each sessions as session, i }
            <div class="flex items-center">
                <div>
                    {#if (session.agent.is_desktop)}
                    <svg class="size-8 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25" />
                    </svg>
                    {:else}
                    <svg class="size-8 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                    </svg>
                    {/if}
                </div>


            <!-- Other Browser Sessions -->        
                <div class="ms-3">
                    <div class="text-sm text-gray-600 dark:text-gray-400">
                        { session.agent.platform ? session.agent.platform : 'Unknown' } - {session.agent.browser ? session.agent.browser : 'Unknown' }
                    </div>

                    <div>
                        <div class="text-xs text-gray-500">
                            { session.ip_address },
                            {#if (session.is_current_device)}
                            <span class="text-green-500 font-semibold">This device</span>
                            {:else}
                            <span>Last active { session.last_active }</span>
                            {/if}
                        </div>
                    </div>
                </div>
            </div>
            {/each}
        </div>
        {/if}
        <div class="mt-5">
            <button class="btn btn-primary text-white" on:click|preventDefault={confirmLogout}>
                Log Out Other Browser Sessions
            </button>

            {#if $form.recentlySuccessful }
            <ActionMessage style="success">
                    Sessions are cleared.
            </ActionMessage>
            {/if}
        </div>

        <!-- Log Out Other Devices Confirmation Modal -->
        <DialogModal id="confirmSessionsLogout" closeText="Cancel">
                <svelte:fragment slot="title">
                    Log Out Other Browser Sessions
                </svelte:fragment>
                <svelte:fragment slot="content">
                    <p class="my-2">Please enter your password to confirm you would like to log out of your other browser sessions across all of your devices.</p>

                    <label class="form-control w-full max-w-md">
                      <div class="label hidden">
                        <span class="label-text">Password</span>
                      </div>
                      <input type="password" placeholder="" class="input input-bordered w-full max-w-md" bind:value={$form.password} bind:this={passwordInput} />
                      
                      {#if ($form.errors.password)}
                      <div class="label">
                        <span class="label-text-alt text-error">{$form.errors.password}</span>
                      </div>
                      {/if}
                    </label>

                </svelte:fragment>
                <svelte:fragment slot="actions">
                    <button class="btn btn-primary {$form.processing ? 'opacity-25' : ''}" disabled={$form.processing} on:click={logoutOtherBrowserSessions}>Log Out Other Browser Sessions</button>
                </svelte:fragment>

            </DialogModal>

	</svelte:fragment>
    
	
</ActionSection>
