<script>
  import { inertia, Link } from '@inertiajs/svelte'

	export const color = '#777777';
  export const bgcolor = '#dddddd';
	export let height = '80';
	export let width = '250';
  export let message = width + 'x' + height;

  let fontsize = height * 0.5;

  //console.log(message);

</script>



<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 {width} {height}" width="{width}" height="{height}">
  
  <rect width="{width}" height="{height}" fill="#cccccc"></rect>
  <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-family="monospace" font-size="{fontsize}" fill="{color}">{message}</text>   
</svg>